import React, { useState, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { useStudyPlan, StudyPlanDay } from '@21st-night/study-plan-web';
import { Button, Spaced } from '@21st-night/ui';
import { StudyPlanWeek } from '@21st-night/web';
import { StudyPlanDragDropContext } from './StudyPlanDragDropContext';
import { StudyPlanDayContainer } from './StudyPlanDayContainer';

interface Week {
  days: StudyPlanDay[];
  number: number;
  startDate: Date;
}

function getFirstDayOfPlanWeek(startWeekOn: number) {
  const currentDay = new Date().getDay();
  return dayjs(new Date())
    .subtract(currentDay, 'day')
    .add(startWeekOn, 'day')
    .toDate();
}

export const StudyPlanFull: React.FC = () => {
  const plan = useStudyPlan();
  const [showingPreviousWeeks, setShowingPreviousWeeks] = useState(false);
  const [firstWeek, setFirstWeek] = useState(
    getFirstDayOfPlanWeek(plan.startWeekOn),
  );

  const onClickShowPreviousWeek = useCallback(() => {
    setFirstWeek(value => dayjs(value).subtract(7, 'day').toDate());
    setShowingPreviousWeeks(true);
  }, []);

  const onClickHidePreviousWeeks = useCallback(() => {
    setFirstWeek(getFirstDayOfPlanWeek(plan.startWeekOn));
    setShowingPreviousWeeks(false);
  }, [plan.startWeekOn]);

  const weeks: Week[] = useMemo(
    () =>
      Object.values(plan.days)
        .sort((a, b) => a.date.getTime() - b.date.getTime())
        .reduce((weeks, day) => {
          const weekday = day.date.getDay();
          const isNewWeek = weekday === plan.startWeekOn;

          if (isNewWeek || weeks.length === 0) {
            const newWeek: Week = {
              number: weeks.length + 1,
              startDate: day.date,
              days: [day],
            };
            return [...weeks, newWeek];
          }

          weeks[weeks.length - 1].days.push(day);

          return weeks;
        }, [] as Week[]),
    [plan],
  );

  const displayedWeeks = weeks.filter(
    week =>
      dayjs(week.startDate).isSame(firstWeek, 'date') ||
      dayjs(week.startDate).isAfter(firstWeek, 'date'),
  );

  return (
    <StudyPlanDragDropContext>
      <Spaced centered style={{ marginBottom: 16 }}>
        {displayedWeeks.length !== weeks.length && (
          <Button size="large" onClick={onClickShowPreviousWeek}>
            Show previous week
          </Button>
        )}
        {showingPreviousWeeks && (
          <Button size="large" onClick={onClickHidePreviousWeeks}>
            Hide past weeks
          </Button>
        )}
      </Spaced>
      {displayedWeeks.map(week => (
        <StudyPlanWeek key={week.number} week={week} number={week.number}>
          {week.days.map((day, index) => (
            <StudyPlanDayContainer day={day} index={index} />
          ))}
        </StudyPlanWeek>
      ))}
    </StudyPlanDragDropContext>
  );
};
