import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import reload from './libs/reload';
import PageLoader from './components/PageLoader';
import PrivateRoute from './components/PrivateRoute';
import UnsubscribeFromEmails from './pages/UnsubscribeFromEmailsPage';
import { OnboardingPage } from './pages/OnboardingPage';
import CreateStudyPlan from './pages/WelcomePage/CreateStudyPlanPage';
import Deck from './routes/DeckRoutes';
import { StudentPage } from './pages/StudentPage/StudentPage';

const Authentication = lazy(reload(() => import('./pages/AuthenticationPage')));
const Logout = lazy(reload(() => import('./pages/LogoutPage')));
const Demo = lazy(reload(() => import('./pages/DemoPage')));
const Info = lazy(reload(() => import('./pages/InfoPage')));
const Dashboard = lazy(reload(() => import('./pages/DashboardPage')));
const Subscription = lazy(reload(() => import('./pages/SubscriptionPage')));
const StudyPlan = lazy(reload(() => import('./pages/StudyPlan')));
const PublicStudyPlan = lazy(
  reload(() => import('./pages/PublicStudyPlanPage')),
);
const DeckTest = lazy(reload(() => import('./pages/DeckTestPage')));
const Subscribe = lazy(reload(() => import('./pages/SubscribePage')));
const CommunityDecks = lazy(reload(() => import('./pages/CommunityDecksPage')));
const MobileUpload = lazy(
  reload(() => import('./pages/FileUploadPage/MobileUploadPage')),
);
const DeckSharedAnalytics = lazy(
  reload(() => import('./pages/DeckPage/DeckSharedAnalyticsPage')),
);

const MediaPlayer = lazy(
  reload(() => import('./pages/MobileApp/MediaPlayerPage')),
);
const ImageOcclusion = lazy(
  reload(() => import('./pages/ImageOcclusionPage/ImageOcclusionPage')),
);
const PublicationApprovalPage = lazy(
  reload(() => import('./pages/PublicationApprovalPage')),
);
const PublicationApprovalDeck = lazy(
  reload(() =>
    import('./pages/PublicationApprovalPage/PublicationApprovalDeck'),
  ),
);
const DeckShareInvitation = lazy(
  reload(() => import('./pages/DeckPage/DeckShareInvitationPage')),
);
const DeckPublicPreview = lazy(
  reload(() => import('./pages/DeckPage/DeckPublicPreviewPage')),
);
const PremiumDeckPage = lazy(reload(() => import('./pages/PremiumDeckPage')));
const DeckSharedAnalyticsAuthenticationPage = lazy(
  reload(() =>
    import(
      './pages/DeckPage/DeckSharedAnalyticsPage/DeckSharedAnalyticsAuthenticationPage'
    ),
  ),
);
const StudyPlanTemplatePage = lazy(
  reload(() => import('./pages/StudyPlanTemplatePage/StudyPlanTemplatePage')),
);
// const Welcome = lazy(() => import('./pages/WelcomePage'));
const RedirectToDeckInvitationPage = ({ match }) => (
  <Redirect
    to={`/deck-invitation/${match.params.deckId}/${match.params.invitationId}`}
  />
);
const RedirectToAddCardsPage = ({ match }) => (
  <Redirect to={`/deck/${match.params.deckId}/add-cards`} />
);

const Routes = () => (
  <Suspense fallback={<PageLoader />}>
    <Switch>
      <Route exact path="/" component={Authentication} />
      <Route path="/info" component={Info} />
      <Route path="/logout" component={Logout} />
      <Route path="/demo" component={Demo} />
      <PrivateRoute path="/welcome" component={OnboardingPage} />
      <PrivateRoute
        path="/create-study-plan/:version/:studentId?/:studentEmail?/:templateId?"
        component={CreateStudyPlan}
      />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute
        exact
        allowUnsubscribed
        path="/subscription"
        component={Subscription}
      />
      <PrivateRoute
        exact
        path="/study-plan-template/:id"
        component={StudyPlanTemplatePage}
      />
      <PrivateRoute
        exact
        path="/study-plan/deck/:deckId"
        component={StudyPlan}
      />
      <PrivateRoute
        exact
        path="/student/:studentId/:studentEmail/:tab?"
        component={StudentPage}
      />
      <Route exact path="/study-plan/:userId" component={PublicStudyPlan} />
      <PrivateRoute exact path="/study-plan" component={StudyPlan} />
      <Route
        exact
        path="/deck/:deckId/share/:invitationId"
        component={RedirectToDeckInvitationPage}
      />
      <Route
        exact
        path="/deck/:deckId/new-card"
        component={RedirectToAddCardsPage}
      />
      <Route
        exact
        path="/deck/:deckId/new-personal-card/:userId"
        component={RedirectToAddCardsPage}
      />
      <PrivateRoute
        exact
        path="/deck-invitation/:deckId/:invitationId"
        component={DeckShareInvitation}
      />
      <PrivateRoute
        exact
        path="/community-deck/:deckId"
        component={DeckPublicPreview}
      />
      <PrivateRoute
        exact
        path="/premium-deck/:deckId"
        component={PremiumDeckPage}
      />
      <Route
        // exact
        path="/deck-progress/:deckId/:userId"
        component={DeckSharedAnalytics}
      />
      <PrivateRoute path="/deck/:deckId" component={Deck} />
      <Route
        path="/progress/login/:analyticsUserId?"
        component={DeckSharedAnalyticsAuthenticationPage}
      />
      <PrivateRoute path="/community-decks" component={CommunityDecks} />
      <PrivateRoute
        allowUnsubscribed
        path="/unsubscribe"
        component={UnsubscribeFromEmails}
      />
      <PrivateRoute
        allowUnsubscribed
        path="/subscribe/:source?"
        component={Subscribe}
      />
      <PrivateRoute path="/upload/:type/:id" component={MobileUpload} />
      <PrivateRoute
        exact
        path="/publish-approval"
        component={PublicationApprovalPage}
      />
      <PrivateRoute
        exact
        path="/publish-approval/:deckId"
        component={PublicationApprovalDeck}
      />
      <PrivateRoute exact path="/test/:userId/:deckId" component={DeckTest} />
      <Route path="/media-player" component={MediaPlayer} />
      <Route path="/image-occlusion" component={ImageOcclusion} />
    </Switch>
  </Suspense>
);

export default Routes;
