import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@21st-night/ui';
import {
  SignupDialog,
  EmailAuthenticationFormFieldValues,
  EmailAuthenticationFormFieldErrors,
  EmailAuthenticationFormView,
} from '@21st-night/authentication-web';
import {
  validate,
  firebaseError,
} from '../../features/authentication/containers/AuthenticationContainer';
import analytics from '../../libs/analytics';
import { firebase, auth, functions } from '../../libs/firebase';

const convertDemoAccount = functions.httpsCallable('convertDemoAccount');

export const DemoSignupDialog: React.FC = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<EmailAuthenticationFormFieldErrors>({});
  const [values, setValues] = useState<EmailAuthenticationFormFieldValues>({
    email: '',
    password: '',
    repeatPassword: '',
    terms: false,
  });

  function openDialog() {
    setOpen(true);
  }

  function closeDialog() {
    setOpen(false);
  }

  async function linkSocialAccount(provider: firebase.auth.AuthProvider) {
    if (!auth.currentUser) {
      return;
    }

    setLoading(true);

    await auth.currentUser.linkWithPopup(provider);
    await convertDemoAccount();

    setLoading(false);
    closeDialog();

    history.replace('/welcome');
  }

  function handleClickGoogleButton() {
    const provider = new firebase.auth.GoogleAuthProvider();
    linkSocialAccount(provider);
  }

  function handleClickAppleButton() {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    linkSocialAccount(provider);
  }

  function handleEmailFormFieldChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const { name, value, checked } = event.target;
    if (name === 'terms') {
      setValues(vals => ({ ...vals, terms: checked }));
    } else {
      setValues(vals => ({ ...vals, [name]: value }));
    }
  }

  async function handleEmailFormSubmit(
    view: EmailAuthenticationFormView,
    values: EmailAuthenticationFormFieldValues,
  ) {
    const [valid, fieldErrors] = validate(view, values);

    if (!auth.currentUser) {
      return;
    }

    if (valid) {
      // Disable the form and reset errors
      setLoading(true);
      setErrors({});

      try {
        // Attempt to sign up the user
        const credential = firebase.auth.EmailAuthProvider.credential(
          values.email,
          values.password,
        );
        await auth.currentUser.linkWithCredential(credential);
        await convertDemoAccount();

        // Log the event to analytics
        analytics.logEvent('sign_up', {
          method: 'email',
        });

        history.push('/welcome');
      } catch (error) {
        // Set server side errors
        setErrors(firebaseError(error));
      }
    } else {
      setErrors(fieldErrors);
      setLoading(false);
    }
  }

  return (
    <>
      <Button color="primary" variant="outlined" onClick={openDialog}>
        Sign up
      </Button>
      <SignupDialog
        open={open}
        loading={loading}
        onClose={closeDialog}
        onClickAppleButton={handleClickAppleButton}
        onClickGoogleButton={handleClickGoogleButton}
        onEmailFormFieldChange={handleEmailFormFieldChange}
        onSubmitEmailForm={handleEmailFormSubmit}
        emailFormValues={values}
        emailFormErrors={errors}
      />
    </>
  );
};
