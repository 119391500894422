import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForward from '@material-ui/icons/ArrowForwardIosRounded';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    color: theme.palette.grey[400],
    marginRight: theme.spacing(1),
    fontSize: '1.2rem',
  },
  text: {
    // fontSize: '1.5rem',
    fontWeight: 500,
    textDecoration: 'none',
    color: theme.palette.grey[400],
    '&:hover': {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
  },
  secondary: {
    color: theme.palette.primary.light,
    fontWeight: 400,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      marginLeft: 0,
      display: 'block',
    },
  },
}));

function DeckName({
  className: classNameProp = '',
  deck,
  primaryLink,
  secondary = <span />,
  ...other
}) {
  const classes = useStyles();
  return (
    <div className={classes.root} {...other}>
      <ArrowForward className={classes.arrow} />
      <Typography
        variant="h3"
        component={primaryLink ? Link : 'h3'}
        to={primaryLink}
        className={clsx(classes.text, classNameProp)}
      >
        {deck.name}
      </Typography>
      <Typography variant="h3" className={clsx(classes.text, classNameProp)}>
        <span className={classes.secondary}>{secondary}</span>
      </Typography>
    </div>
  );
}

DeckName.propTypes = {
  className: PropTypes.string,
  deck: PropTypes.object.isRequired,
  secondary: PropTypes.node,
  primaryLink: PropTypes.string,
};

export default DeckName;
