import React, { useEffect, useMemo, useState } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import {
  StandardDeckProvider,
  DeckProvider as LegacyDeckProvider,
  StandardDeckConsumer,
  useUser,
  DeckCheatSheetProvider,
} from '@21st-night/core';
import { ReviewStateProvider } from '@21st-night/review-web';
import { DeckTabsValue } from '@21st-night/types';
import { useFirebase } from '@21st-night/utils-web';
import {
  Deck,
  deserializeDeckDocument,
  DeckContentProvider,
} from '@21st-night/deck-web';
import PageLoader from '../components/PageLoader';
import { DeckPage } from '../pages/DeckPage/new';

type RouteParams = { deckId: string; tab?: DeckTabsValue };

const DeckRoute: React.FC<RouteComponentProps<RouteParams>> = ({ match }) => {
  const { deckId } = match.params;
  const { db } = useFirebase();
  const user = useUser();
  const legacyDeck = user.deck(deckId);
  const deckRef = useMemo(() => db.doc(`/users/${user.id}/decks/${deckId}`), [
    db,
    user.id,
    deckId,
  ]);
  const [deck, setDeck] = useState<Deck | null>(null);

  useEffect(() => {
    setDeck(null);
    const unsubscribe = deckRef.onSnapshot(snapshot => {
      if (snapshot.exists) {
        setDeck(deserializeDeckDocument(snapshot));
      }
    });

    return () => unsubscribe();
  }, [deckRef]);

  if (!legacyDeck || !deck) {
    return <PageLoader />;
  }

  return (
    <ReviewStateProvider>
      <DeckContentProvider deck={deck} docRef={deckRef}>
        <StandardDeckProvider deck={legacyDeck} userId={user.id as string}>
          <StandardDeckConsumer>
            {(standardDeck): React.ReactNode => (
              <LegacyDeckProvider deck={standardDeck}>
                {/* <Route path="/deck/:deckId/review" component={DeckReview} /> */}
                {/* {match.params.tab !== 'review' && ( */}
                <DeckCheatSheetProvider>
                  <Route
                    exact
                    path="/deck/:deckId/:tab?"
                    component={DeckPage}
                  />
                </DeckCheatSheetProvider>
                {/* )} */}
              </LegacyDeckProvider>
            )}
          </StandardDeckConsumer>
        </StandardDeckProvider>
      </DeckContentProvider>
    </ReviewStateProvider>
  );
};

export default DeckRoute;
