/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@21st-night/styles';
import { ReturnToStack } from '@21st-night/icons';
import {
  Deck,
  DeckAddNoteButton,
  DeckAddFlashcardButton,
  DeckFilter,
  DeckFilterDateInput,
  DeckSort,
  useDeckContent,
  DeckItemCard,
  DeckItemCreateCard,
  DeckItemNote,
  DeckContentItem,
  DeckItemNoteFileUpload,
  generateDeckNote,
} from '@21st-night/deck-web';
import {
  Spaced,
  SplitViewList,
  SplitViewListItemPosition,
  useSplitViewList,
  Fab,
} from '@21st-night/ui';
import { useFirebase } from '@21st-night/utils-web';
import { createNote, generateNoteFromFile } from '@21st-night/notes-web';
import { useUser } from '@21st-night/core';
import { DeckAddErrorLogButton } from '../DeckPage/new/DeckAddErrorLogButton';
import { SplitButton } from '../DeckPage/new/DeckPageContent';

const useStyles = makeStyles(theme => ({
  header: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  filtering: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  addContentButtons: {
    marginTop: theme.spacing(2),
  },
  addContentButton: {
    flex: 1,
  },
  content: {},
}));

// export interface SplitButtonProps {
//   item: DeckContentItem;
//   onSplit: (leftId: string, rightId: string) => void;
// }

// export const SplitButton: React.FC<SplitButtonProps> = ({ onSplit, item }) => {
//   const deck = useDeckContent();
//   const { auth, db } = useFirebase();
//   const fileInput = useRef<HTMLInputElement | null>(null);
//   const [open, setOpen] = useState(false);
//   const [position, setPosition] = useState({ top: 0, left: 0 });
//   const [noteSelectionOpen, setNoteSelectionOpen] = useState(false);
//   const [cardSelectionOpen, setCardSelectionOpen] = useState(false);

//   function openMenu(event: React.MouseEvent<HTMLButtonElement>) {
//     const rect = event.currentTarget.getBoundingClientRect();

//     setOpen(true);
//     setPosition({
//       top: rect.top,
//       left: rect.right,
//     });
//   }

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleCreateNote = () => {
//     if (!auth.currentUser) {
//       return;
//     }

//     setOpen(false);
//     const note = generateNote(deck.id, auth.currentUser.uid, {
//       cards: ['flashcard', 'error-log'].includes(item.type) ? [item.id] : [],
//     });
//     onSplit(item.id, note.id);
//     setTimeout(() => {
//       createNote(db, note);
//       deck.addNoteToDeck(generateDeckNote(note));
//     });
//   };

//   const openNoteSelection = () => {
//     setNoteSelectionOpen(true);
//     setOpen(false);
//   };

//   const closeNoteSelection = () => {
//     setNoteSelectionOpen(false);
//   };

//   const handleSelectNote = (note: DeckContentNote) => {
//     closeNoteSelection();
//     onSplit(item.id, note.id);
//   };

//   const openCardSelection = () => {
//     setCardSelectionOpen(true);
//     setOpen(false);
//   };

//   const closeCardSelection = () => {
//     setCardSelectionOpen(false);
//   };

//   const handleSelectCard = (card: DeckContentCard) => {
//     closeCardSelection();
//     onSplit(item.id, card.id);
//   };

//   const handleCreateFlashcard = () => {
//     setOpen(false);
//     const newCard = deck.addCard('flashcard', {
//       category: item.category,
//       subcategories: item.subcategories,
//       note: item.type === 'note' ? item.id : null,
//     });

//     onSplit(item.id, newCard.id);
//   };

//   const handleCreateErrorLog = () => {
//     setOpen(false);
//     const newCard = deck.addCard('error-log', {
//       category: item.category,
//       subcategories: item.subcategories,
//       note: item.type === 'note' ? item.id : null,
//     });

//     onSplit(item.id, newCard.id);
//   };

//   function openFileSelect() {
//     if (fileInput.current) {
//       setOpen(false);
//       fileInput.current.click();
//     }
//   }

//   function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
//     if (event.target.files) {
//       const fileType = event.target.files[0].type;
//       let upload: DeckContentNoteFileUpload | null = null;
//       if (fileType === 'application/pdf') {
//         [upload] = deck.addNoteFileUploads(
//           Array.from(event.target.files),
//           'pdf-upload',
//         );
//       } else if (fileType.startsWith('image')) {
//         [upload] = deck.addNoteFileUploads(
//           Array.from(event.target.files),
//           'image-upload',
//         );
//       } else if (fileType === 'text/plain') {
//         [upload] = deck.addNoteFileUploads(
//           Array.from(event.target.files),
//           'text-upload',
//         );
//       }

//       if (upload) {
//         onSplit(item.id, upload.id);
//       }
//     }
//   }

//   return (
//     <>
//       <Fab size="small" onClick={openMenu}>
//         <Add fontSize="small" />
//       </Fab>
//       <Menu
//         PaperProps={{ style: { minWidth: 180 } }}
//         anchorPosition={position}
//         anchorReference="anchorPosition"
//         keepMounted
//         open={open}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'right',
//         }}
//         transformOrigin={{
//           vertical: 'top',
//           horizontal: 'right',
//         }}
//       >
//         <Typography
//           variant="overline"
//           style={{ marginLeft: 16, fontWeight: 'bold' }}
//         >
//           Card
//         </Typography>
//         <MenuItem onClick={handleCreateFlashcard}>New flashcard</MenuItem>
//         <MenuItem onClick={handleCreateErrorLog}>New error log</MenuItem>
//         <MenuItem onClick={openCardSelection}>Existing card</MenuItem>
//         <Typography
//           variant="overline"
//           style={{
//             marginLeft: 16,
//             paddingTop: 16,
//             display: 'block',
//             fontWeight: 'bold',
//           }}
//         >
//           Note
//         </Typography>
//         <MenuItem onClick={handleCreateNote}>New note</MenuItem>
//         <MenuItem onClick={openFileSelect}>Upload a file</MenuItem>
//         <MenuItem onClick={openNoteSelection}>Existing note</MenuItem>
//       </Menu>
//       <DeckNoteSelectionDialog
//         open={noteSelectionOpen}
//         onClose={closeNoteSelection}
//         onSelectNote={handleSelectNote}
//         exclude={[item.id]}
//       />
//       <DeckCardSelectionDialog
//         open={cardSelectionOpen}
//         onClose={closeCardSelection}
//         onSelectCard={handleSelectCard}
//         exclude={[item.id]}
//       />
//       {ReactDOM.createPortal(
//         <input
//           ref={fileInput}
//           type="file"
//           multiple={false}
//           accept="application/pdf, image/*, text/plain"
//           style={{
//             opacity: 0,
//             pointerEvents: 'none',
//             position: 'fixed',
//             left: -1000,
//             top: -1000,
//           }}
//           onChange={handleFileChange}
//         />,
//         document.body,
//       )}
//     </>
//   );
// };

export const StudentDeck: React.FC = () => {
  const classes = useStyles();
  const { auth, db } = useFirebase();
  const user = useUser();
  const deck = useDeckContent();
  const {
    rows,
    splitItems,
    removeFromSplit,
    unsplitItems,
    addToSplit,
  } = useSplitViewList<DeckContentItem>();

  const handleNoteFileUploadSuccess = async (
    url: string,
    id: string,
    file: File,
    noteId: string,
  ) => {
    if (!auth.currentUser) {
      return;
    }

    const upload = deck.noteFileUploads.find(upload => upload.id === noteId);

    if (!upload) {
      return;
    }

    const fileData = { url, id, file };
    const note = await generateNoteFromFile(
      deck.id,
      auth.currentUser.uid,
      fileData,
      { id: upload.id },
    );
    createNote(db, note);
    deck.addNoteToDeck(generateDeckNote(note));
    deck.removeNoteFileUpload(upload.file);
  };

  const renderItem = (
    item: DeckContentItem,
    index: number,
    position: SplitViewListItemPosition,
    opposite: null | string | string[],
  ) => {
    function handleRemove() {
      if (position === 'right' && typeof opposite === 'string') {
        removeFromSplit(opposite, item.id);
      } else if (position === 'left') {
        unsplitItems(item.id);
      }
    }

    switch (item.type) {
      case 'note':
      case 'pdf':
      case 'image':
        return (
          <DeckItemNote
            autoFocus={index === 0}
            key={item.id}
            // note={item as DeckContentNote}
            note={item}
          />
        );
      case 'pdf-upload':
      case 'image-upload':
      case 'text-upload':
        return (
          <DeckItemNoteFileUpload
            noteId={item.id}
            key={item.id}
            file={item.file}
            onUploadSuccess={handleNoteFileUploadSuccess}
          />
        );
      case 'flashcard':
      case 'error-log':
        return <DeckItemCard key={item.id} card={item} />; // as DeckContentCard
      case 'create-flashcard':
      case 'create-error-log':
        return (
          <DeckItemCreateCard
            key={item.id}
            data={item}
            type={item.type.includes('flashcard') ? 'flashcard' : 'error-log'}
            onCancel={handleRemove}
          />
        );
      default:
        return '';
    }
  };

  const filtered = deck.filter(deck.content);
  const sorted = deck.sort(filtered);

  function renderSplitButton(item: DeckContentItem) {
    return <SplitButton item={item} onSplit={splitItems} />;
  }

  function renderAddSplitButton(item: DeckContentItem) {
    return <SplitButton item={item} onSplit={addToSplit} />;
  }

  return (
    <>
      <div className={classes.header}>
        <Spaced centered spacing={2} className={classes.addContentButtons}>
          <DeckAddNoteButton
            className={classes.addContentButton}
            onSelectPdfFiles={files =>
              deck.addNoteFileUploads(files, 'pdf-upload')
            }
            onSelectImageFiles={files =>
              deck.addNoteFileUploads(files, 'image-upload')
            }
            onSelectTextFiles={files =>
              deck.addNoteFileUploads(files, 'text-upload')
            }
            importDecks={(user.decks as unknown) as Deck[]}
          />
          <DeckAddFlashcardButton
            importDecks={(user.decks as unknown) as Deck[]}
            className={classes.addContentButton}
          />
          <DeckAddErrorLogButton
            importDecks={(user.decks as unknown) as Deck[]}
            className={classes.addContentButton}
          />
        </Spaced>
        <Spaced className={classes.filtering}>
          <DeckFilter />
          {deck.filters.find(item => item.value === 'date-added') && (
            <DeckFilterDateInput />
          )}
          <DeckSort />
        </Spaced>
      </div>
      <div className={classes.content}>
        <SplitViewList
          items={rows(sorted)}
          renderItem={renderItem}
          renderSplitButton={renderSplitButton}
          renderAddSplitButton={renderAddSplitButton}
          renderCloseButton={(leftId, rightId) => (
            <Fab
              size="small"
              onClick={() => {
                removeFromSplit(leftId, rightId);
              }}
              style={{ width: 32, height: 32, minHeight: 32 }}
            >
              <ReturnToStack />
            </Fab>
          )}
        />
      </div>
    </>
  );
};
