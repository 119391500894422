import React, { Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Toolbar,
  Typography,
  Hidden,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MenuRounded';
import ArrowForward from '@material-ui/icons/ArrowForwardIosRounded';
import { useUser as useLegacyUser } from '@21st-night/core';
import {
  Pomodoro as PomodoroTimer,
  ActionButtons,
  IconButton,
  Logo,
  StreakCounter,
  GoldCounter,
  Button,
} from '@21st-night/web';
import { Link } from 'react-router-dom';
import { useUser, AuthenticatedUserContext } from '@21st-night/user';
import { StudyPlanPopover } from '../StudyPlanProvider/StudyPlanPopover';
import { DemoSignupDialog } from '../DemoSignupDialog';

export interface Breadcrumb {
  title: string;
  to: string;
}

export interface AppBarProps extends MuiAppBarProps {
  /**
   * Breadcrumbs leading from the logo.
   */
  breadcrumbs?: Breadcrumb[];
  /**
   * If `true`, actions will be shown.
   */
  disableActions?: boolean;
  /**
   * If `true`, logo will not be rendered.
   */
  disableLogo?: boolean;
  /**
   * Callback fired when the "Coaching" button is clicked.
   */
  onClickCoachingButton?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Callback fired when the menu button is clicked.
   */
  onClickMenuButton: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      zIndex: 50,
      backgroundColor: theme.palette.background.default,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    breadcrumb: {
      color: theme.palette.grey[400],
      textDecoration: 'none',
      cursor: 'pointer',
      transition: theme.transitions.create(['color'], {
        duration: theme.transitions.duration.shortest,
      }),
      '&:hover': {
        color: theme.palette.primary.light,
      },
    },
    breadcrumbArrow: {
      color: theme.palette.grey[400],
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      fontSize: '1.2rem',
    },
    breadcrumbActive: {
      fontWeight: 'bold',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
    },
    flex: {
      flex: 1,
    },
  }),
);

const AppBar: React.FC<AppBarProps> = ({
  children,
  className: classNameProp,
  disableLogo = false,
  onClickMenuButton,
  onClickCoachingButton,
  disableActions,
  breadcrumbs = [],
  ...other
}) => {
  const user = useUser() as AuthenticatedUserContext;
  const legacyUser = useLegacyUser();
  const classes = useStyles();
  const { isAnonymous } = user;
  const isStudent =
    user.onboarding &&
    user.segmentation &&
    user.onboarding.variant !== 'TUTOR' &&
    user.segmentation.values[0] !== 'tutoring';

  return (
    <MuiAppBar
      position="fixed"
      color="inherit"
      elevation={0}
      className={clsx(classes.root, classNameProp)}
      {...other}
    >
      <Toolbar>
        {!disableLogo && <Logo />}
        <div className={classes.content}>
          {breadcrumbs.map((crumb, index) => (
            <Fragment key={crumb.to}>
              <ArrowForward className={classes.breadcrumbArrow} />
              <Typography
                variant="h3"
                component={Link}
                to={crumb.to}
                className={clsx(classes.breadcrumb, {
                  [classes.breadcrumbActive]: index === breadcrumbs.length - 1,
                })}
              >
                {crumb.title}
              </Typography>
            </Fragment>
          ))}
          {children}
        </div>
        <div className={classes.flex} />
        <Hidden only="xs">
          {!disableActions && isStudent && (
            <ActionButtons spaced>
              {!isAnonymous && !!onClickCoachingButton && (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={onClickCoachingButton}
                >
                  Coaching
                </Button>
              )}
              {isAnonymous && <DemoSignupDialog />}
              <PomodoroTimer />
              <StudyPlanPopover />
              <StreakCounter
                currentStreak={legacyUser.currentStreak || 0}
                recentStreak={legacyUser.recentStreak}
              />
              <GoldCounter value={legacyUser.gold || 0} />
              <IconButton
                aria-label="Open main navigation"
                onClick={onClickMenuButton}
              >
                <MenuIcon />
              </IconButton>
            </ActionButtons>
          )}
          {!isStudent && !disableActions && (
            <ActionButtons spaced>
              <PomodoroTimer />
              <IconButton
                aria-label="Open main navigation"
                onClick={onClickMenuButton}
              >
                <MenuIcon />
              </IconButton>
            </ActionButtons>
          )}
        </Hidden>
        <Hidden smUp>
          <IconButton
            aria-label="Open main navigation"
            onClick={onClickMenuButton}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
