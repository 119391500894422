/* eslint-disable import/prefer-default-export */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Spaced } from '@21st-night/ui';
import { toDate } from '@21st-night/utils-web';
import { useDeck, DeckFilter, DeckFilterDateInput } from '@21st-night/deck-web';
import { AnalyticsView } from '@21st-night/analytics-web';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1000,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 300,
    paddingTop: 24,
  },
  filtering: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const DeckPageAnalytics: React.FC = () => {
  const classes = useStyles();
  const deck = useDeck();

  return (
    <div className={classes.root}>
      {/* <List>
        <ListItem
          button
          onClick={(): void => {
            user.toggleDailyReportEmailsForDeck(deck);
            if (!deck.dailyReportEmail) {
              user.incrementStatistic('analytics-emails');
            }
          }}
          style={{ backgroundColor: '#ECEFF1' }}
        >
          <Checkbox
            edge="start"
            checked={!!deck.dailyReportEmail}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': 'email-daily-reports' }}
          />
          <ListItemText
            id="email-daily-reports"
            primary="Email me daily session reports"
          />
        </ListItem>
      </List> */}
      <Spaced className={classes.filtering}>
        <DeckFilter />
        {deck.filters.find(item => item.value === 'date-added') && (
          <DeckFilterDateInput />
        )}
      </Spaced>
      <AnalyticsView cards={deck.filter(deck.cards)} />
    </div>
  );
};
