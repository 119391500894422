import React from 'react';
import {
  OnboardingProvider as Provider,
  OnboardingStateProvider,
} from '@21st-night/onboarding-web';
import { useUser } from '@21st-night/core';

export const OnboardingProvider: React.FC = ({ children }) => {
  const user = useUser();

  if (!user.id) {
    return <>{children}</>;
  }

  return (
    <OnboardingStateProvider initialState={user.onboarding || {}}>
      <Provider userId={user.id}>{children}</Provider>
    </OnboardingStateProvider>
  );
};
