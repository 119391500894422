import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { useSpring, animated } from 'react-spring';
import { motion, useCycle } from 'framer-motion';
import { ArrowBackRounded } from '@material-ui/icons';
import { incrementStatistic, incrementStreak } from '@21st-night/gamification';
import { StudyPlanDay, StudyPlanItem } from '@21st-night/study-plan-web';
import { Button as UiButton } from '@21st-night/ui';
import { makeStyles } from '@material-ui/core/styles';
import { useUser, useStudyPlan } from '@21st-night/core';
import { useFirebase } from '@21st-night/utils-web';
import StudyPlanCreator from './StudyPlanCreator';
import StudyPlanTemplatePreview from './StudyPlanTemplatePreview';
import DateSelect from './components/DateSelect';
import Buttons from './components/Buttons';
import getStudyPlanTemplateId from './getStudyPlanTemplateId';

type View = 'end-date' | 'start-date' | 'plan';

type RouteParams = {
  studentEmail?: string;
  studentId?: string;
  templateId?: string;
  version: 'custom' | 'template';
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  question: {
    textAlign: 'center',
    fontSize: '34px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '26px',
    },
    marginBottom: theme.spacing(3),
  },
  backButton: {
    position: 'absolute',
    top: 8,
    left: 8,
  },
}));

const CreateStudyPlanPage: React.FC<RouteComponentProps<RouteParams>> = ({
  match,
}) => {
  const { version, studentId, studentEmail, templateId } = match.params;
  const { db } = useFirebase();
  const classes = useStyles();
  const history = useHistory();
  const { setPlan } = useStudyPlan();
  const user = useUser();
  const [isOpen, toggleOpen] = useCycle(false, true);
  const [view, setView] = useState<View>('end-date');
  const [endDate, setEndDate] = useState(dayjs());
  const [startDate, setStartDate] = useState(dayjs());
  const { opacity, transform } = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? 0 : 40,
    config: {
      mass: 5,
      tension: isOpen ? 800 : 1000,
      friction: isOpen ? 65 : 80,
    },
  });

  useEffect(() => {
    toggleOpen();
  }, []);

  const handleClickBack = useCallback(() => {
    if (view === 'start-date') {
      setView('end-date');
    } else if (view === 'plan') {
      setView('start-date');
    } else if (studentId && studentEmail) {
      history.push(`/student/${studentId}/${studentEmail}/study-plan`);
    } else {
      history.push('/dashboard');
    }
  }, [view]);

  const goToView = useCallback(
    (view: View) => {
      return () => {
        toggleOpen();
        setTimeout(() => {
          setView(view);
          toggleOpen();
        }, 500);
      };
    },
    [view],
  );

  const handleStudyPlanCreated = useCallback(
    async (days: StudyPlanDay[], items: StudyPlanItem[]) => {
      if (studentId) {
        history.push(`/student/${studentId}/${studentEmail}/study-plan`);
      } else {
        setPlan(days, items);
        await user.update({ hasStudyPlan: true });
        incrementStreak(db, user.id as string);
        incrementStatistic(db, user.id as string, 'study-plans-created');
        history.push('/study-plan');
      }
    },
    [db, studentId, studentEmail, user, setPlan, history],
  );

  return (
    <div className={classes.root}>
      <UiButton
        className={classes.backButton}
        startIcon={<ArrowBackRounded />}
        onClick={handleClickBack}
      >
        Back
      </UiButton>
      {view !== 'plan' && (
        <div>
          <animated.div
            style={{
              // @ts-ignore
              opacity: opacity.to(o => o),
              transform: transform.to(o => `translate(0, ${o}px)`),
            }}
          >
            {view === 'end-date' && (
              <div className={classes.question}>
                When is {studentId ? 'their' : 'your'} test?
              </div>
            )}
            {view === 'start-date' && (
              <div className={classes.question}>
                When will {studentId ? 'they' : 'you'} start studying?
              </div>
            )}
          </animated.div>
          <motion.nav initial={false} animate={isOpen ? 'open' : 'closed'}>
            <Buttons>
              {view === 'end-date' && (
                <DateSelect
                  onChange={value => setEndDate(value as Dayjs)}
                  value={endDate}
                  onClickNext={goToView('start-date')}
                />
              )}
              {view === 'start-date' && (
                <DateSelect
                  onChange={value => setStartDate(value as Dayjs)}
                  value={startDate}
                  onClickNext={goToView('plan')}
                  maxDate={endDate.toDate()}
                  inputFormat={
                    dayjs().isSame(startDate, 'day')
                      ? '[Today]'
                      : 'D MMMM, YYYY'
                  }
                />
              )}
            </Buttons>
          </motion.nav>
        </div>
      )}
      {view === 'plan' && version === 'custom' && (
        <StudyPlanCreator
          userId={studentId || (user.id as string)}
          startDate={startDate.toDate()}
          endDate={endDate.toDate()}
          onCreated={handleStudyPlanCreated}
        />
      )}
      {view === 'plan' && version === 'template' && (
        <StudyPlanTemplatePreview
          userId={studentId || (user.id as string)}
          startDate={startDate.toDate()}
          endDate={endDate.toDate()}
          onCreated={handleStudyPlanCreated}
          templatePath={
            templateId
              ? `/users/${user.id}/study-plan-templates/${templateId}`
              : `/study-plan-templates/${getStudyPlanTemplateId(
                  user.onboarding ? user.onboarding.variant : '',
                  endDate.diff(startDate, 'day'),
                )}`
          }
        />
      )}
    </div>
  );
};

export default CreateStudyPlanPage;
