import React from 'react';
import { captureException } from '@sentry/browser';
import { Typography, Link } from '@material-ui/core';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    captureException(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            display: 'flex',
            height: '100vh',
            width: '100vw',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Typography gutterBottom variant="h5">
            Something went wrong.
          </Typography>
          <Typography paragraph>
            Please refresh the page to reload the app.
          </Typography>
          <Typography paragraph style={{ maxWidth: 400 }}>
            Our development team has been notified about the issue. If you keep
            running into issues, please contact us at{' '}
            <Link href="mailto:info@get21stnight.com">
              info@get21stnight.com
            </Link>
            .
          </Typography>
          <Typography paragraph>Sorry about that!</Typography>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
