import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EmailList from '../DeckShareEmailList';

export const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    maxWidth: 340,
    margin: '0 auto',
  },
  title: {
    textAlign: 'center',
  },
}));

function DeckShareInstructorSuccess({
  className: classNameProp,
  children,
  emails,
  ...other
}) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, classNameProp)} {...other}>
      <Typography gutterBottom variant="h5" className={classes.title}>
        Success
      </Typography>
      <Typography gutterBottom variant="body2" color="textSecondary">
        {emails.length > 1 && (
          <Fragment>
            <strong>{emails.length} instructors</strong> have been sent an
            invitation to this deck.
          </Fragment>
        )}
        {emails.length === 1 && (
          <Fragment>
            <strong>{emails[0]}</strong> has been sent an invitation to this
            deck.
          </Fragment>
        )}
      </Typography>
      <EmailList emails={emails} />
    </div>
  );
}

DeckShareInstructorSuccess.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /**
   * List of emails the deck was shared with.
   */
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
};

DeckShareInstructorSuccess.defaultProps = {};

export default DeckShareInstructorSuccess;
