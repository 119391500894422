/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from 'react';
import { makeStyles, cn } from '@21st-night/styles';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { useDeck } from '@21st-night/deck-web';
import { incrementStatistic, incrementStreak } from '@21st-night/gamification';
import {
  useDeck as useLegacyDeck,
  useUser as useLegacyUser,
} from '@21st-night/core';
import { AuthenticatedUserContext, useUser } from '@21st-night/user';
import {
  CardReviewProgress,
  getProficiencyKey,
  ReviewProvider,
} from '@21st-night/review-web';
import { toDate, useFirebase } from '@21st-night/utils-web';
import { DeckPageSettings } from './DeckPageSettings';
import { DeckPageContent } from './DeckPageContent';
import { DeckPageCheatSheet } from './DeckPageCheatSheet';
import { DeckPageAnalytics } from './DeckPageAnalytics';
import { DeckOnboarding } from './DeckOnboarding';
import DeckShareContainer from '../../../features/deck/containers/DeckShareDialogContainer';
import AppLayout from '../../../components/AppLayout';
import DeckName from '../../../features/deck/components/DeckName';
import { DeckReview } from './DeckReview';

export type DeckPageTab =
  | 'content'
  | 'settings'
  | 'cheat-sheet'
  | 'analytics'
  | 'review'
  | 'share';

export type DeckPageProps = RouteComponentProps<{
  deckId: string;
  tab?: DeckPageTab;
}>;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: '60vh',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(1),
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabsContainer: {
    maxHeight: 48,
    flex: 1,
    position: 'relative',
    backgroundColor: '#F5F7FA',
  },
  tab: {
    minWidth: 80,
    textTransform: 'none',
  },
  shareContainer: {
    maxWidth: 1000,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const DeckPage: React.FC<DeckPageProps> = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  const user = useUser() as AuthenticatedUserContext;
  const legacyUser = useLegacyUser();
  const deck = useDeck();
  const { db } = useFirebase();
  const { deckId, tab: defaultTab } = match.params;
  const [tab, setTab] = React.useState<DeckPageTab>(defaultTab || 'content');
  const legacyDeck = useLegacyDeck();
  const cardProgressCollectionRef = useMemo(
    () => db.collection(`/users/${user.uid}/card-progress`),
    [db, user.uid],
  );
  const reviewSessionCollectionRef = useMemo(
    () =>
      db.collection(
        `/users/${user.uid}/decks/${legacyDeck.id}/review-sessions`,
      ),
    [db, user.uid, legacyDeck.id],
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const { isAnonymous } = user;

  useEffect(() => {
    if (defaultTab) {
      setTab(defaultTab);
    }
  }, [defaultTab]);

  const handleTabChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    nextTab: DeckPageTab,
  ) => {
    setTab(nextTab);
    history.push(`/deck/${deckId}/${nextTab}`);
  };

  const handleCardRated = (
    cardId: string,
    nextProgress: CardReviewProgress,
  ) => {
    const card = deck.cards.find(card => card.id === cardId);

    if (!card) {
      return;
    }
    deck.updateDeckCard(cardId, {
      ...nextProgress,
      firstReview: toDate(nextProgress.firstReview),
      lastReview: toDate(nextProgress.lastReview),
    });
    incrementStreak(db, user.uid);
    incrementStatistic(db, user.uid, 'cards-reviewed');

    if (
      getProficiencyKey({
        notStarted: false,
        proficiency: card.proficiency || 0,
      }) !== 'mastered' &&
      getProficiencyKey({
        notStarted: false,
        proficiency: nextProgress.proficiency,
      }) === 'mastered'
    ) {
      incrementStatistic(db, user.uid, 'cards-mastered');
    }
  };

  const handleFinishReviewSession = () => {
    setTab('content');
    history.push(`/deck/${deckId}/content`);
  };

  if (!user.uid) {
    return <div />;
  }

  return (
    <AppLayout
      loading={!deck}
      appBarContent={
        <DeckName
          deck={deck}
          primaryLink={`/deck/${match.params.deckId}/content`}
          style={{ marginLeft: 8 }}
        />
      }
    >
      {deck && (
        <ReviewProvider
          cardProgressCollectionRef={cardProgressCollectionRef}
          reviewSessionCollectionRef={reviewSessionCollectionRef}
          onRateCard={handleCardRated}
          onFinishReviewSession={handleFinishReviewSession}
        >
          {tab !== 'review' && (
            <>
              <Paper square className={classes.tabsContainer}>
                <Tabs
                  centered
                  value={tab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleTabChange}
                >
                  <Tab label="Deck" value="content" className={classes.tab} />
                  <Tab
                    label="Cheat Sheet"
                    value="cheat-sheet"
                    className={classes.tab}
                  />
                  <Tab
                    label="Analytics"
                    value="analytics"
                    className={classes.tab}
                  />
                  <Tab label="Share" value="share" className={classes.tab} />
                  <Tab
                    label="Settings"
                    value="settings"
                    className={classes.tab}
                  />
                </Tabs>
              </Paper>
              <div className={cn(classes.root)}>
                {deck && (
                  <>
                    {!isAnonymous && <DeckOnboarding legacyDeck={legacyDeck} />}
                    {tab === 'content' && <DeckPageContent />}
                    {tab === 'cheat-sheet' && <DeckPageCheatSheet />}
                    {tab === 'analytics' && <DeckPageAnalytics />}
                    {tab === 'share' && (
                      <div className={classes.shareContainer}>
                        <DeckShareContainer
                          deck={legacyDeck}
                          user={legacyUser}
                        />
                      </div>
                    )}
                    {tab === 'settings' && <DeckPageSettings />}
                  </>
                )}
              </div>
            </>
          )}
          {tab === 'review' && <DeckReview />}
        </ReviewProvider>
      )}
    </AppLayout>
  );
};
