import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ActionButtons } from '@21st-night/web';

export const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    backgroundColor: '#F5F7FA',
  },
  title: {
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flex: 'none',
    justifyContent: 'center',
    margin: '0 auto',
    width: '100%',
  },
}));

function DeckShareDialog({
  actions,
  className: classNameProp,
  children,
  deckName,
  ...other
}) {
  const classes = useStyles();
  return (
    <div fullScreen classes={{ paper: classes.root }} {...other}>
      <div className={classes.content}>{children}</div>
      <ActionButtons marginTop spaced align="center">
        {actions}
      </ActionButtons>
    </div>
  );
}

DeckShareDialog.propTypes = {
  actions: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /**
   * The name of the deck.
   */
  deckName: PropTypes.string.isRequired,
};

DeckShareDialog.defaultProps = {};

export default DeckShareDialog;
