import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { cn, makeStyles } from '@21st-night/styles';
import { useUser } from '@21st-night/core';
import { useFirebase } from '@21st-night/utils-web';
import {
  OnboardingQuestionair,
  OnboardingAnswers,
  useOnboarding,
  useOnboardingState,
} from '@21st-night/onboarding-web';
import dayjs from 'dayjs';
import { LoadingIndicator, Typography } from '@21st-night/ui';
import StudyPlanCreator from '../WelcomePage/StudyPlanCreator';
import StudyPlanTemplatePreview from '../WelcomePage/StudyPlanTemplatePreview';
import getStudyPlanTemplateId from '../WelcomePage/getStudyPlanTemplateId';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 160,
  },
  withQuestionair: {
    '& > :first-child': {
      flex: 1,
    },
  },
  loading: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 160,
  },
  loadingIndicator: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

function getTemplateId(contentType: string, startDate: Date, endDate: Date) {
  const planLength = dayjs(endDate).diff(startDate, 'day');
  return getStudyPlanTemplateId(contentType, planLength);
}

export const OnboardingPage: React.FC = () => {
  const classes = useStyles();
  const { functions } = useFirebase();
  const history = useHistory();
  const user = useUser();
  const onboarding = useOnboarding();
  const onboardingState = useOnboardingState();
  const [loading, setLoading] = useState(false);
  const { answers } = onboarding;
  const createOnboardingDeck = functions.httpsCallable('createOnboardingDeck');

  useEffect(() => {
    if (
      !onboarding.question &&
      !loading &&
      onboardingState.progress !== 88 &&
      onboardingState.progress !== 100
    ) {
      onboardingState.setProgress(88);
    }
  }, [onboarding.question, loading, onboardingState]);

  const setFullProgress = useCallback(() => {
    onboardingState.setProgress(100);
  }, [onboardingState]);

  const completeSegmentation = useCallback(
    async (result: OnboardingAnswers) => {
      setTimeout(() => {
        setFullProgress();
      });
      setLoading(true);
      if (result['content-type']) {
        await createOnboardingDeck({ contentType: result['content-type'] });
      }
      await onboarding.setUserSegmentation(result);
      setLoading(false);
      history.replace('/dashboard');
    },
    [createOnboardingDeck, history, onboarding, setFullProgress],
  );

  const onSubmit = useCallback(
    (result: OnboardingAnswers) => {
      if (
        (result['user-type'] && result['user-type'] !== 'student') ||
        !result['study-plan-type']
      ) {
        completeSegmentation(result);
      }
    },
    [completeSegmentation],
  );

  const handleStudyPlanCreated = useCallback(async () => {
    setLoading(true);
    await user.update({ hasStudyPlan: true });
    completeSegmentation(onboarding.answers);
  }, [completeSegmentation, onboarding.answers, user]);

  const planStart = answers['start-studying-date'] as Date;
  const planEnd = answers['exam-date'] as Date;
  const planType = answers['study-plan-type'] as string;
  const contentType = onboarding.answers['content-type'] as string;

  return (
    <div
      className={cn(
        classes.root,
        onboarding.question && classes.withQuestionair,
      )}
    >
      <OnboardingQuestionair
        onSubmit={onSubmit}
        onSkip={completeSegmentation}
      />
      {!onboarding.question && !loading && planType === 'custom' && (
        <StudyPlanCreator
          userId={user.id || ''}
          startDate={planStart}
          endDate={planEnd}
          onStartLoading={setFullProgress}
          onCreated={handleStudyPlanCreated}
        />
      )}
      {!onboarding.question && !loading && planType === 'template' && (
        <StudyPlanTemplatePreview
          userId={user.id || ''}
          startDate={planStart}
          endDate={planEnd}
          onStartLoading={setFullProgress}
          onCreated={handleStudyPlanCreated}
          templatePath={`/study-plan-templates/${getTemplateId(
            contentType,
            planStart,
            planEnd,
          )}`}
        />
      )}
      {loading && (
        <div className={classes.loading}>
          <LoadingIndicator size="large" className={classes.loadingIndicator} />
          <Typography variant="h6" color="textSecondary">
            Setting up your account...
          </Typography>
        </div>
      )}
    </div>
  );
};
