import { db } from '../../libs/firebase';

const cards = {
  /**
   * Creates a new card.
   *
   * @param {object} data The card data.
   *
   * @returns {Promise}
   */
  create: data => db.collection('cards').add(data),

  /**
   * Updates a card by id.
   *
   * @param {string} id   The card document ID.
   * @param {object} data The update data.
   *
   * @returns {Promise}
   */
  update: (id, data) => db.collection('cards').doc(id).update(data),

  /**
   * Deletes a card by id.
   *
   * @param {string} id The card document ID.
   *
   * @returns {Promise}
   */
  delete: card => {
    const promises = [
      // Delete the card itself
      db.collection('cards').doc(card.id).delete(),
    ];

    return Promise.all(promises);
  },
};

export default cards;
