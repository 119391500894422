/* eslint-disable no-param-reassign */
import { action } from 'easy-peasy';

const subscriptionModel = {
  // State
  loading: false,
  view: 'plans',
  coupon: null,
  plan: null,

  // Actions
  choosePlan: action((state, payload) => {
    state.view = 'payment-form';
    state.plan = payload.plan;
  }),
};

export default subscriptionModel;
