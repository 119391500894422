import React, { useMemo } from 'react';
import { useUser } from '@21st-night/core';
import { useFirebase } from '@21st-night/utils-web';
import {
  useStudyPlan,
  StudyPlanTemplatesProvider,
  StudyPlanDay,
} from '@21st-night/study-plan-web';
import PageLoader from '../../components/PageLoader';
import { StudentCreateStudyPlan } from './StudentCreateStudyPlan';
import { StudyPlanFull } from '../../components/StudyPlanProvider/StudyPlanFull';

interface Week {
  days: StudyPlanDay[];
  startDate: Date;
}

export type StudentStudyPlanProps = {
  studentEmail: string;
  studentId: string;
  studentName: string;
};

export const StudentStudyPlan: React.FC<StudentStudyPlanProps> = ({
  studentId,
  studentEmail,
  studentName,
}) => {
  const user = useUser();
  const plan = useStudyPlan();
  const { db } = useFirebase();
  const templatesCollectionRef = useMemo(
    () => db.collection(`/users/${user.id}/study-plan-templates`),
    [db, user.id],
  );

  if (plan.loading) {
    return <PageLoader />;
  }

  if (!Object.keys(plan.days).length) {
    return (
      <StudyPlanTemplatesProvider
        templatesCollectionRef={templatesCollectionRef}
      >
        <StudentCreateStudyPlan
          studentName={studentName}
          studentId={studentId}
          studentEmail={studentEmail}
        />
      </StudyPlanTemplatesProvider>
    );
  }

  return <StudyPlanFull />;
};
