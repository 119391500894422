/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import { StudyPlanDay, useStudyPlan } from '@21st-night/study-plan-web';
import { ListItem, ListItemText, Typography } from '@21st-night/ui';
import { StudyPlanDay as StudyPlanDayComponent } from '@21st-night/web';
import { Droppable } from 'react-beautiful-dnd';
import { StudyPlanItemContainer } from './StudyPlanItemContainer';
import { StudyPlanAddTask } from './StudyPlanAddTask';

export interface StudyPlanDayContainerProps {
  day: StudyPlanDay;
  index: number;
}

export const StudyPlanDayContainer: React.FC<StudyPlanDayContainerProps> = ({
  index,
  day,
}) => {
  const plan = useStudyPlan();

  return (
    <Droppable key={day.id} droppableId={day.id}>
      {(provided, droppableState): React.ReactElement => (
        <div ref={provided.innerRef}>
          <StudyPlanDayComponent
            divider={index !== 6}
            date={day.date}
            isDraggingOver={
              droppableState.isDraggingOver || droppableState.isUsingPlaceholder
            }
          >
            {day.items
              .filter(item => plan.items[item])
              .map(item => plan.items[item])
              .map((item, itemIndex) => (
                <StudyPlanItemContainer
                  item={item}
                  index={itemIndex}
                  isDragging={
                    droppableState.isDraggingOver ||
                    droppableState.isUsingPlaceholder
                  }
                />
              ))}
            {provided.placeholder}
            {day.items.length === 0 && (
              <ListItem>
                <ListItemText disableTypography>
                  <Typography color="textSecondary">Review</Typography>
                </ListItemText>
              </ListItem>
            )}
            <StudyPlanAddTask day={day} />
          </StudyPlanDayComponent>
        </div>
      )}
    </Droppable>
  );
};
