/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { makeStyles } from '@material-ui/core/styles';
import { incrementStatistic, incrementStreak } from '@21st-night/gamification';
import { useFirebase } from '@21st-night/utils-web';
import {
  Typography,
  DeleteButton,
  TextField,
  Button,
  LoadingIndicator,
  Spaced,
} from '@21st-night/ui';
import {
  updateDeck,
  useDeck,
  DeckImportCardsDialog,
  DeckImportNotesDialog,
  generateDeckCardBatch,
  generateDeckCardReviewData,
  Deck,
} from '@21st-night/deck-web';
import {
  CardData,
  CardType,
  createCardBatch,
  generateCardBatch,
  ImportFromCsvDialog,
  ImportFromQuizletDialog,
  ImportFromAnkiDialog,
} from '@21st-night/cards-web';
import { useUser } from '@21st-night/core';
import { functions as fbFunctions } from '../../../libs/firebase';

type ImportDialogId =
  | null
  | 'csv'
  | 'anki'
  | 'quizlet'
  | 'deck-cards'
  | 'deck-notes';

export interface ImportCardsFromAnkiProps {
  deck: string;
  type: CardType;
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(2, 0, 8, 0),
  },
  sectionHeading: {
    marginBottom: theme.spacing(2),
  },
  section: {
    margin: theme.spacing(3, 0),
  },
  subsection: {
    marginBottom: theme.spacing(2),
  },
  importButton: {
    justifyContent: 'flex-start',
    marginLeft: theme.spacing(-1.5),
    marginRight: theme.spacing(-3),
  },
  fileInput: {
    opacity: 0,
    pointerEvents: 'none',
    position: 'fixed',
    left: -1000,
    top: -1000,
  },
}));

export const DeckPageSettings: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { db, functions, storage } = useFirebase();
  const deck = useDeck();
  const user = useUser();
  const [name, setName] = useState(deck.name);
  const [debouncedName] = useDebounce(name, 500);
  const [importDialog, setImportDialog] = useState<ImportDialogId>(null);
  const [importCardType, setImportCardType] = useState<CardType>('flashcard');
  const [exportingCsv, setExportingCsv] = useState(false);

  useEffect(() => {
    if (deck.name !== debouncedName) {
      updateDeck(deck.docRef, { name: debouncedName });
    }
  }, [deck.docRef, deck.name, debouncedName]);

  function deleteDeck() {
    deck.deleteDeck();
    history.replace('/dashboard');
  }

  function closeImportDialog() {
    setImportDialog(null);
  }

  function openCardImportDialog(dialog: ImportDialogId, cardType: CardType) {
    setImportDialog(dialog);
    setImportCardType(cardType);
  }

  async function handleCreateCardBatch(data: CardData[]) {
    if (!user.id) {
      return;
    }

    const cards = generateCardBatch(user.id, deck.id, importCardType, data);

    await createCardBatch(db, cards);

    incrementStreak(db, user.id);
    incrementStatistic(db, user.id, 'cards-created', cards.length);

    let deckCards = generateDeckCardBatch(data, cards);
    if (deck.type === 'student') {
      deckCards = deckCards.map(card => ({
        ...card,
        ...generateDeckCardReviewData(importCardType),
      }));
    }

    deck.addCardsToDeck(deckCards);
  }

  async function exportCsv() {
    const exportDeck = fbFunctions.httpsCallable('exportDeckToCsv');

    setExportingCsv(true);

    const result = await exportDeck({ id: deck.id });

    if (result && result.data) {
      const file = storage.ref(result.data.file);
      const url = await file.getDownloadURL();

      window.open(url);
    }

    setExportingCsv(false);
  }

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <Typography variant="h3" className={classes.sectionHeading}>
          Deck settings
        </Typography>
        <TextField
          fullWidth
          label="Deck name"
          placeholder="My Deck"
          value={name}
          onChange={event => setName(event.target.value)}
        />
      </div>
      <div className={classes.section}>
        <Typography variant="h3" className={classes.sectionHeading}>
          Import content
        </Typography>
        <div className={classes.subsection}>
          <Typography gutterBottom variant="subtitle1" color="textSecondary">
            Flashcards
          </Typography>
          <Button
            fullWidth
            size="large"
            className={classes.importButton}
            onClick={() => openCardImportDialog('csv', 'flashcard')}
          >
            Import flashcards from CSV
          </Button>
          <Button
            fullWidth
            size="large"
            className={classes.importButton}
            onClick={() => openCardImportDialog('anki', 'flashcard')}
          >
            Import flashcards from Anki
          </Button>
          <Button
            fullWidth
            size="large"
            className={classes.importButton}
            onClick={() => openCardImportDialog('quizlet', 'flashcard')}
          >
            Import flashcards from Quizlet
          </Button>
          <Button
            fullWidth
            size="large"
            className={classes.importButton}
            onClick={() => openCardImportDialog('deck-cards', 'flashcard')}
          >
            Import flashcards from another deck
          </Button>
        </div>
        <div className={classes.subsection}>
          <Typography gutterBottom variant="subtitle1" color="textSecondary">
            Error logs
          </Typography>
          <Button
            fullWidth
            size="large"
            className={classes.importButton}
            onClick={() => openCardImportDialog('csv', 'error-log')}
          >
            Import error logs from CSV
          </Button>
          <Button
            fullWidth
            size="large"
            className={classes.importButton}
            onClick={() => openCardImportDialog('anki', 'error-log')}
          >
            Import error logs from Anki
          </Button>
          <Button
            fullWidth
            size="large"
            className={classes.importButton}
            onClick={() => openCardImportDialog('quizlet', 'error-log')}
          >
            Import error logs from Quizlet
          </Button>
          <Button
            fullWidth
            size="large"
            className={classes.importButton}
            onClick={() => openCardImportDialog('deck-cards', 'error-log')}
          >
            Import error logs from another deck
          </Button>
        </div>
        <div className={classes.subsection}>
          <Typography gutterBottom variant="subtitle1" color="textSecondary">
            Notes
          </Typography>
          <Button
            fullWidth
            size="large"
            className={classes.importButton}
            onClick={() => setImportDialog('deck-notes')}
          >
            Import notes from another deck
          </Button>
        </div>
      </div>
      <div className={classes.section}>
        <Typography variant="h3" className={classes.sectionHeading}>
          Export content
        </Typography>
        <div className={classes.subsection}>
          <Button
            fullWidth
            size="large"
            className={classes.importButton}
            onClick={exportCsv}
            disabled={exportingCsv}
          >
            {!exportingCsv && 'Export deck to CSV'}
            {exportingCsv && (
              <Spaced>
                <LoadingIndicator size="small" />
                <span>Exporting...</span>
              </Spaced>
            )}
          </Button>
        </div>
      </div>
      <div className={classes.section}>
        <Typography
          variant="h3"
          color="error"
          className={classes.sectionHeading}
        >
          Danger zone
        </Typography>
        <DeleteButton
          onConfirm={deleteDeck}
          label="Delete deck"
          size="large"
          confirmText={
            <span>
              <strong>Are you sure?</strong> All associated cards, notes, and
              progress will be deleted as well.
            </span>
          }
        />
      </div>

      <ImportFromCsvDialog
        {...{ db, storage, functions }}
        onClose={closeImportDialog}
        open={importDialog === 'csv'}
        createCards={handleCreateCardBatch}
      />
      <ImportFromQuizletDialog
        onClose={closeImportDialog}
        open={importDialog === 'quizlet'}
        createCards={handleCreateCardBatch}
      />
      <ImportFromAnkiDialog
        onClose={closeImportDialog}
        open={importDialog === 'anki'}
        deckId={deck.id}
        cardType={importCardType}
      />
      <DeckImportCardsDialog
        type={importCardType}
        open={importDialog === 'deck-cards'}
        onClose={closeImportDialog}
        decks={(user.decks as unknown) as Deck[]}
        to={deck.id}
      />
      <DeckImportNotesDialog
        open={importDialog === 'deck-notes'}
        onClose={closeImportDialog}
        decks={(user.decks as unknown) as Deck[]}
        to={deck.id}
      />
    </div>
  );
};
