/* eslint-disable import/prefer-default-export */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { DeckCheatSheetView } from '@21st-night/web';
import { useDeck, useUser } from '@21st-night/core';
import DeckImportExport from '../DeckImportExport';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  section: {
    marginTop: theme.spacing(3),
  },
}));

export const DeckPageCheatSheet: React.FC = () => {
  const classes = useStyles();
  const user = useUser();
  const deck = useDeck();

  return (
    <div className={classes.root}>
      <DeckCheatSheetView>
        {!user.hasSeenMessage('cheat-sheet') && (
          <Alert
            style={{ marginBottom: 16 }}
            severity="info"
            variant="filled"
            onClose={(): Promise<void> => user.setMessageSeen('cheat-sheet')}
          >
            Cheat Sheet combines all of the key takeaways from your deck&apos;s
            cards for quick and easy reviewing.
          </Alert>
        )}
        <DeckImportExport deck={deck} />
      </DeckCheatSheetView>
    </div>
  );
};
