import * as React from 'react';
import { motion } from 'framer-motion';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    textAlign: 'center',
    marginTop: 24,
  },
}));

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const Buttons = props => {
  const classes = useStyles();

  return <motion.div variants={variants} className={classes.root} {...props} />;
};

export default Buttons;
