import React, { useState, useEffect } from 'react';
import { makeStyles } from '@21st-night/styles';
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  LoadingIndicator,
} from '@21st-night/ui';
import { RichText } from '@21st-night/web';
import { deserializeDocument } from '@21st-night/editor-web';
import {
  StudyPlan,
  StudyPlanTask,
  StudyPlanItem,
  StudyPlanTemplate,
  StudyPlanTaskTemplate,
  generateStudyPlanTaskFromTemplate,
  generateStudyPlanItems,
  generateStudyPlanDays,
  addStudyPlanItemsToDays,
  createStudyPlan,
  createStudyPlanItems,
  createStudyPlanDays,
  DayOfTheWeekSelect,
  StudyPlanPreview,
  StudyPlanDay,
} from '@21st-night/study-plan-web';
import { useFirebase } from '@21st-night/utils-web';

export type View = 'preview' | 'loading' | 'loading-create';

export interface StudyPlanCreatorProps {
  endDate: Date;
  onCreated: (days: StudyPlanDay[], items: StudyPlanItem[]) => void;
  onStartLoading?: () => void;
  startDate: Date;
  templatePath: string;
  userId: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(1),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bottomSpacer: {
    padding: 100,
  },
  title: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
  subtitle: {
    textAlign: 'center',
    display: 'block',
    marginBottom: theme.spacing(4),
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: theme.palette.text.secondary,
  },
  appBar: {
    bottom: 0,
    top: 'auto',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
  },
  preview: {
    maxWidth: 600,
    width: '100%',
  },
  loadingView: {
    color: theme.palette.text.secondary,
    height: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingIndicator: {
    marginBottom: theme.spacing(2),
  },
}));

const StudyPlanCreator: React.FC<StudyPlanCreatorProps> = ({
  userId,
  startDate,
  endDate,
  onStartLoading,
  onCreated,
  templatePath,
}) => {
  const { db } = useFirebase();
  const classes = useStyles();
  const [view, setView] = useState<View>('loading');
  const [loadingPlan, setLoadingPlan] = useState(true);
  const [loadingTasks, setLoadingTasks] = useState(true);
  const [template, setTemplate] = useState<StudyPlanTemplate | null>(null);
  const [taskTemplates, setTaskTemplates] = useState<StudyPlanTaskTemplate[]>(
    [],
  );
  const [tasks, setTasks] = useState<StudyPlanTask[]>([]);
  const [plan, setPlan] = useState<StudyPlan>({
    createdAt: new Date(),
    startDate,
    endDate,
    startWeekOn: 0,
    description: null,
  });

  useEffect(() => {
    if (templatePath) {
      db.doc(templatePath)
        .get()
        .then(doc => {
          setTemplate(doc.data() as StudyPlanTemplate);
          setLoadingPlan(false);
        });
      db.doc(templatePath)
        .collection('tasks')
        .get()
        .then(snapshot => {
          setTaskTemplates(
            snapshot.docs.map(doc => doc.data() as StudyPlanTaskTemplate),
          );
          setLoadingTasks(false);
        });
    }
  }, [templatePath]);

  useEffect(() => {
    if (
      !loadingPlan &&
      !loadingTasks &&
      template &&
      view !== 'loading-create'
    ) {
      setTasks(
        taskTemplates.map(task =>
          generateStudyPlanTaskFromTemplate(
            task,
            template.length,
            startDate,
            endDate,
          ),
        ),
      );
      setView('preview');
    }
  }, [
    endDate,
    loadingPlan,
    loadingTasks,
    startDate,
    taskTemplates,
    template,
    view,
  ]);

  function handleStartWeekOnChange(
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ): void {
    setPlan(currentPlan => ({
      ...currentPlan,
      startWeekOn: event.target.value as number,
    }));
  }

  async function handleSubmit(): Promise<void> {
    if (onStartLoading) {
      onStartLoading();
    }
    setView('loading-create');
    if (!template) {
      return;
    }
    await createStudyPlan(db, userId, {
      ...plan,
      // @ts-ignore
      template: template.id,
      description: template.description,
    });
    let days = generateStudyPlanDays(plan.startDate, plan.endDate);
    const items = tasks.reduce(
      (itms, task) => [...itms, ...generateStudyPlanItems(task, days)],
      [] as StudyPlanItem[],
    );
    days = addStudyPlanItemsToDays(days, items);
    await createStudyPlanItems(db, userId, items);

    await createStudyPlanDays(db, userId, days);

    onCreated(days, items);
  }

  return (
    <div className={classes.root}>
      {view === 'preview' && template && (
        <>
          <Typography gutterBottom variant="h4" className={classes.title}>
            {template.name}
          </Typography>
          {template.description && (
            <div>
              <RichText document={deserializeDocument(template.description)} />
            </div>
          )}
          <div
            style={{
              marginTop: 16,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography>Start week on</Typography>
            <DayOfTheWeekSelect
              value={plan.startWeekOn}
              onChange={handleStartWeekOnChange}
              style={{ marginLeft: 8 }}
            />
          </div>
          <div className={classes.preview}>
            <StudyPlanPreview plan={plan} tasks={tasks} />
          </div>
        </>
      )}
      {view === 'loading' && (
        <div className={classes.loadingView}>
          <LoadingIndicator size="large" />
        </div>
      )}
      {view === 'loading-create' && (
        <div className={classes.loadingView}>
          <LoadingIndicator size="large" className={classes.loadingIndicator} />
          <Typography variant="h6">Creating study plan...</Typography>
        </div>
      )}
      {view === 'preview' && (
        <>
          <div className={classes.bottomSpacer} />
          <AppBar color="default" position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Confirm Study Plan
              </Button>
            </Toolbar>
          </AppBar>
        </>
      )}
    </div>
  );
};

export default StudyPlanCreator;
