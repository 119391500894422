/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { useFirebase } from '@21st-night/utils-web';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteButton, TextField } from '@21st-night/ui';
import { useUser } from '@21st-night/core';

export interface StudentSettingsProps {
  studentEmail: string;
  studentId: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  section: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export const StudentSettings: React.FC<StudentSettingsProps> = ({
  studentId,
  studentEmail,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const user = useUser();
  const { db } = useFirebase();
  const [name, setName] = useState(
    user.students && user.students[studentId]
      ? user.students[studentId].name
      : studentEmail,
  );
  const [debouncedName] = useDebounce(name, 500);

  useEffect(() => {
    const oldName =
      user.students && user.students[studentId]
        ? user.students[studentId].name
        : studentEmail;
    if (oldName !== debouncedName) {
      user.update({
        [`students.${studentId}`]: {
          id: studentId,
          email: studentEmail,
          name: debouncedName,
        },
      });
    }
  }, [db, user, studentEmail, studentId, debouncedName]);

  function archiveStudent() {
    user.update({
      [`students.${studentId}`]: {
        ...((user.students || {})[studentId] || {}),
        archived: true,
      },
    });
    history.replace('/dashboard');
  }

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <TextField
          fullWidth
          label="Student name"
          placeholder="Jane Doe"
          helperText="Only you will see this"
          value={name}
          onChange={event => setName(event.target.value)}
        />
      </div>
      <DeleteButton
        onConfirm={archiveStudent}
        label="Archive student"
        confirmLabel="Archive"
        confirmText={
          <span>
            <strong>Are you sure?</strong> This student will no longer appear on
            your dashbaord.
          </span>
        }
      />
    </div>
  );
};
