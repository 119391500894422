import React from 'react';
import { makeStyles } from '@21st-night/styles';
import { LoadingIndicator, Typography } from '@21st-night/ui';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  withQuestionair: {
    '& > :first-child': {
      flex: 1,
    },
  },
  loading: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingIndicator: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export const DemoAccountLoadingOverlay: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.loading}>
        <LoadingIndicator size="large" className={classes.loadingIndicator} />
        <Typography variant="h6" color="textSecondary">
          Setting up demo account...
        </Typography>
      </div>
    </div>
  );
};
