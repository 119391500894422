function reload(fn) {
  if (process.env.NODE_ENV === 'development') {
    return fn;
  }
  return () =>
    new Promise(resolve => {
      fn()
        .then(resolve)
        .catch(error => {
          window.location.reload(true);
        });
    });
}

export default reload;
