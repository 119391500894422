import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route } from 'react-router-dom';

const RouterContext = React.createContext(null);

export const Router = ({ children }) => (
  <BrowserRouter>
    <Route>
      {routeProps => (
        <RouterContext.Provider value={routeProps}>
          {children}
        </RouterContext.Provider>
      )}
    </Route>
  </BrowserRouter>
);

Router.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useRouter() {
  return React.useContext(RouterContext);
}
