import React from 'react';
import { Tooltip } from '@21st-night/ui';
import {
  DeckAddCardButton,
  DeckAddCardButtonProps,
} from '@21st-night/deck-web';

export type DeckAddErrorLogButtonProps = Omit<DeckAddCardButtonProps, 'type'>;

export const DeckAddErrorLogButton: React.FC<DeckAddErrorLogButtonProps> = props => (
  <DeckAddCardButton
    renderButton={button => (
      <Tooltip
        placement="top"
        title={
          <span>
            An error log is a way to remember techniques. Create error logs for
            math questions, science questions, and any questions that involve
            remembering <em>how</em> to solve a problem.
          </span>
        }
      >
        <div>{button}</div>
      </Tooltip>
    )}
    type="error-log"
    {...props}
  />
);
