/* eslint-disable @typescript-eslint/unbound-method */
import React, { useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Droppable } from 'react-beautiful-dnd';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Popover, Typography, Chip } from '@material-ui/core';
import { Spaced, IconButton, DatePicker } from '@21st-night/ui';
import { ChevronLeft, ChevronRight } from '@21st-night/icons';
import { useUser } from '@21st-night/core';
import { generateStudyPlanDay, useStudyPlan } from '@21st-night/study-plan-web';
import { Button } from '@21st-night/web';
import { StudyPlanItemContainer } from './StudyPlanItemContainer';
import { StudyPlanAddTask } from './StudyPlanAddTask';
import { StudyPlanDragDropContext } from './StudyPlanDragDropContext';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      overflow: 'visible',
    },
    count: {
      minWidth: 24,
    },
    buttonLabel: {
      fontWeight: 'bold',
      textTransform: 'none',
    },
    buttonStartIcon: {
      '& > *:first-child': {
        fontSize: '13px',
      },
    },
    paper: {
      width: 500 + theme.spacing(4),
      overflow: 'visible',
      boxSizing: 'border-box',
      position: 'relative',
      padding: theme.spacing(3, 2, 3, 2),
      zIndex: 'auto',
      boxShadow:
        '0px 3px 5px -1px rgba(0, 0, 0, 0.1),0px 5px 8px 0px rgba(0, 0, 0, 0.07),0px 1px 14px 0px rgba(0, 0, 0, 0.06)',
      marginTop: 11,
    },
    arrow: {
      position: 'absolute',
      top: -32,
      right: 14,
      width: 32,
      height: 32,
      overflow: 'hidden',
      boxShadow: '0 16px 10px -17px rgba(0, 0, 0, 0.5)',
      '&::after': {
        content: '""',
        position: 'absolute',
        borderTopLeftRadius: 2,
        width: 16,
        height: 16,
        backgroundColor: '#FAFAFA',
        transform: 'rotate(45deg)',
        top: 24,
        left: 8,
        boxShadow: '-1px -1px 10px -5px rgba(0, 0, 0, 0.5)',
      },
    },
    dateSelect: {
      marginBottom: theme.spacing(2),
    },
    content: {
      maxHeight: 500,
      overflowY: 'scroll',
    },
  }),
);

export const StudyPlanPopover: React.FC = () => {
  const classes = useStyles();
  const button = useRef<HTMLDivElement | null>(null);
  const user = useUser();
  const plan = useStudyPlan();
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(new Date());

  const day =
    Object.values(plan.days).find(day =>
      dayjs(day.date).isSame(date, 'date'),
    ) || generateStudyPlanDay(date);
  const todaysTasks = day.items
    .filter(itemId => !!plan.items[itemId])
    .map(itemId => plan.items[itemId]);

  function previousDay() {
    setDate(dayjs(date).subtract(1, 'day').toDate());
  }

  function nextDay() {
    setDate(dayjs(date).add(1, 'day').toDate());
  }

  return (
    <div ref={button}>
      <Button
        variant="outlined"
        startIcon={
          <Chip
            size="small"
            label={todaysTasks.filter(task => !task.done).length}
            className={classes.count}
          />
        }
        classes={{ startIcon: classes.buttonStartIcon }}
        onClick={() => setOpen(true)}
      >
        <span className={classes.buttonLabel}>Tasks</span>
      </Button>
      <Popover
        classes={{ root: classes.root, paper: classes.paper }}
        anchorEl={button.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={() => setOpen(false)}
      >
        {user.hasStudyPlan && (
          <>
            <Spaced centered className={classes.dateSelect}>
              <IconButton onClick={previousDay}>
                <ChevronLeft />
              </IconButton>
              <Typography variant="h6" color="textPrimary">
                <DatePicker
                  value={date}
                  onChange={value => setDate(value as Date)}
                  inputFormat={
                    dayjs().isSame(date, 'day') ? '[Today]' : 'D MMMM, YYYY'
                  }
                />
              </Typography>
              <IconButton onClick={nextDay}>
                <ChevronRight />
              </IconButton>
            </Spaced>
            <StudyPlanDragDropContext>
              <Droppable droppableId={day.id}>
                {(provided, droppableState): React.ReactElement => (
                  <div ref={provided.innerRef} className={classes.content}>
                    {todaysTasks.map((item, itemIndex) => (
                      <StudyPlanItemContainer
                        background="paper"
                        item={item}
                        index={itemIndex}
                        isDragging={
                          droppableState.isDraggingOver ||
                          droppableState.isUsingPlaceholder
                        }
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </StudyPlanDragDropContext>
            <StudyPlanAddTask day={day} />
            <Spaced centered>
              <Button
                size="small"
                color="default"
                to="/study-plan"
                component={Link}
              >
                Open study plan
              </Button>
            </Spaced>
          </>
        )}
        {!user.hasStudyPlan && (
          <div>
            <Typography
              color="textSecondary"
              style={{
                maxWidth: 500,
                textAlign: 'center',
                marginBottom: 16,
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              Studying is more effective with a study plan! Let&apos;s get you a
              personalized
              {user.onboarding &&
              ['GMAT', 'GRE', 'MCAT', 'LSAT'].includes(user.onboarding.variant)
                ? ` ${user.onboarding.variant} study plan. Would you rather`
                : ' study plan.'}
            </Typography>
            {user.onboarding &&
            ['GMAT', 'GRE', 'MCAT', 'LSAT'].includes(
              user.onboarding.variant,
            ) ? (
              <>
                <Button
                  fullWidth
                  variant="outlined"
                  component={Link}
                  to="/create-study-plan/custom"
                  style={{ marginBottom: 8 }}
                >
                  Create a study plan from scratch
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  component={Link}
                  to="/create-study-plan/template"
                >
                  Adapt one of our study plans
                </Button>
              </>
            ) : (
              <Button
                fullWidth
                variant="outlined"
                component={Link}
                to="/create-study-plan/custom"
              >
                Create a study plan
              </Button>
            )}
          </div>
        )}
        <div className={classes.arrow} />
      </Popover>
    </div>
  );
};
