import { createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4C63B6',
      dark: '#35469C',
      light: '#98AEEB',
      900: '#19216C',
      800: '#2D3A8C',
      700: '#35469C',
      600: '#4055A8',
      500: '#4C63B6',
      400: '#647ACB',
      300: '#7B93DB',
      200: '#98AEEB',
      100: '#BED0F7',
      50: '#E0E8F9',
    },
    secondary: green,
    text: {
      secondary: 'rgb(153, 153, 153)',
    },
    proficiency: {
      notStarted: '#9E9E9E',
      needsWork: '#FF5252',
      inProgress: '#F5A623',
      mastered: '#7ED321',
      retired: '#9F3D9C',
    },
    background: {
      default: '#F5F7FA',
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    h1: {
      fontSize: '2.25rem',
      fontWeight: 400,
      lineHeight: 1.3,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.3,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.3,
    },
    h6: {
      fontWeight: 700,
      fontSize: '16px',
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '21px',
    },
  },
});

// theme.typography.h1 = {
//   fontSize: '3rem',
//   fontWeight: 300,
//   [theme.breakpoints.down('xs')]: {
//     fontSize: '2rem',
//   },
// };

// theme.typography.h2 = {
//   fontSize: '2rem',
//   fontWeight: 'normal',
//   [theme.breakpoints.down('xs')]: {
//     fontSize: '1.2rem',
//   },
// };

export default theme;
