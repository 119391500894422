/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { BasicEditor } from '@21st-night/editor-web';

export const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 440,
    margin: '0 auto',
  },
  terms: {
    marginTop: theme.spacing(1),
  },
  termsLabel: {
    fontSize: '.8rem',
    lineHeight: '1rem',
  },
}));

function DeckSharePublishForm({
  className: classNameProp,
  children,
  disabled,
  errors,
  onFieldChange,
  onDescriptionChange,
  description,
  values,
  ...other
}) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, classNameProp)} {...other}>
      <FormHelperText>
        Briefly describe your deck to help community members understand its
        contents.
      </FormHelperText>
      {errors.other && <FormHelperText error>{errors.other}</FormHelperText>}
      <BasicEditor
        placeholder="Description"
        value={description}
        onChange={onDescriptionChange}
      />
      {errors.description && (
        <FormHelperText error>{errors.description}</FormHelperText>
      )}
      <FormControlLabel
        control={
          <Checkbox
            error={!!errors.terms}
            name="terms"
            checked={values.terms}
            onChange={onFieldChange}
            disabled={disabled}
          />
        }
        classes={{ root: classes.terms, label: classes.termsLabel }}
        label={
          <span>
            I have read and agreed to 21st Night&apos;s{' '}
            <a
              href="https://get21stnight.com/terms-of-use/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>
          </span>
        }
      />
      {errors.terms && <FormHelperText error>{errors.terms}</FormHelperText>}
    </div>
  );
}

DeckSharePublishForm.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /**
   * If `true`, all fields will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Field errors.
   */
  errors: PropTypes.shape({
    description: PropTypes.string,
    terms: PropTypes.string,
    other: PropTypes.string,
  }),
  /**
   * Callback fired when a field changes.
   */
  onFieldChange: PropTypes.func.isRequired,
  /**
   * Callback fired when description changes.
   */
  onDescriptionChange: PropTypes.func.isRequired,
  description: PropTypes.arrayOf(PropTypes.object),
  /**
   * The field values.
   */
  values: PropTypes.shape({
    terms: PropTypes.bool,
  }).isRequired,
};

DeckSharePublishForm.defaultProps = {
  errors: {},
  disabled: false,
};

export default DeckSharePublishForm;
