/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@21st-night/styles';
import { Select, MenuItem } from '@21st-night/ui';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Tabs, Tab, Paper } from '@material-ui/core';
import {
  DeckContentProvider,
  deserializeDeckDocument,
  Deck,
} from '@21st-night/deck-web';
import { useFirebase } from '@21st-night/utils-web';
import { useUser } from '@21st-night/core';
import {
  StudyPlanProvider as StudyPlanDataProvider,
  StudyPlanStateProvider,
} from '@21st-night/study-plan-web';
import AppLayout from '../../components/AppLayout';
import DeckName from '../../features/deck/components/DeckName';
import { StudentDeck } from './StudentDeck';
import { StudentAnalytics } from './StudentAnalytics';
import { StudentStudyPlan } from './StudentStudyPlan';
import { StudentSettings } from './StudentSettings';

export type DeckPageTab = 'deck' | 'analytics' | 'settings' | 'study-plan';

export type DeckPageProps = RouteComponentProps<{
  studentEmail: string;
  studentId: string;
  tab?: DeckPageTab;
}>;

const useStyles = makeStyles(theme => ({
  content: {
    width: '100%',
    minHeight: '60vh',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(1),
    maxWidth: 816,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabsContainer: {
    maxHeight: 48,
    flex: 1,
    position: 'relative',
    backgroundColor: '#F5F7FA',
  },
  tab: {
    minWidth: 80,
    textTransform: 'none',
  },
  analyticsContainer: {
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  shareContainer: {
    maxWidth: 1000,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const StudentPage: React.FC<DeckPageProps> = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  const { db } = useFirebase();
  const user = useUser();
  const [decks, setDecks] = useState<Deck[]>([]);
  const [selectedDeck, setSelectedDeck] = useState<Deck | null>(null);
  const { studentId, studentEmail, tab: defaultTab } = match.params;
  const [tab, setTab] = useState<DeckPageTab>(defaultTab || 'deck');
  const [hasOpenedStudyPlan, setHasOpenedStudyPlan] = useState(
    tab === 'study-plan',
  );
  const name =
    user.students && user.students[studentId]
      ? user.students[studentId].name
      : studentEmail;

  useEffect(() => {
    if (defaultTab) {
      setTab(defaultTab);
    }
  }, [defaultTab]);

  useEffect(() => {
    setDecks([]);
    const unsubscribe = db
      .collection('users')
      .doc(studentId)
      .collection('decks')
      .where('teachers', 'array-contains', user.id)
      .onSnapshot(snapshot => {
        const docs = snapshot.docs.map(doc => deserializeDeckDocument(doc));
        if (docs.length) {
          setDecks(docs);
          setSelectedDeck(docs[0]);
        }
      });

    return () => unsubscribe();
  }, [db, studentId]);

  const handleChangeDeck = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const deck = decks.find(deck => deck.id === event.target.value);
    if (deck) {
      setSelectedDeck(deck);
    }
  };

  const handleTabChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    nextTab: DeckPageTab,
  ) => {
    setTab(nextTab);
    if (nextTab === 'study-plan') {
      setHasOpenedStudyPlan(true);
    }
    history.push(`/student/${studentId}/${studentEmail}/${nextTab}`);
  };

  if (!user.id) {
    return <div />;
  }

  return (
    <AppLayout
      loading={!decks.length}
      appBarContent={
        <DeckName
          deck={{ name }}
          primaryLink={`/student/${studentId}/${studentEmail}/decks`}
          style={{ marginLeft: 8 }}
        />
      }
    >
      {selectedDeck && (
        <DeckContentProvider
          deck={selectedDeck}
          docRef={db.doc(`/users/${studentId}/decks/${selectedDeck.id}`)}
        >
          <Paper square className={classes.tabsContainer}>
            <Tabs
              centered
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
            >
              <Tab label="Deck" value="deck" className={classes.tab} />
              <Tab
                label="Analytics"
                value="analytics"
                className={classes.tab}
              />
              <Tab
                label="Study Plan"
                value="study-plan"
                className={classes.tab}
              />
              <Tab label="Settings" value="settings" className={classes.tab} />
            </Tabs>
          </Paper>
          <div className={classes.content}>
            {['deck', 'analytics'].includes(tab) && (
              <Select
                fullWidth
                value={selectedDeck ? selectedDeck.id : ''}
                onChange={handleChangeDeck}
              >
                {decks.map(deck => (
                  <MenuItem key={deck.id} value={deck.id}>
                    {deck.name}
                  </MenuItem>
                ))}
              </Select>
            )}
            {tab === 'deck' && <StudentDeck />}
            {tab === 'analytics' && (
              <StudentAnalytics deck={selectedDeck} studentId={studentId} />
            )}
            {tab === 'study-plan' && (
              <StudyPlanStateProvider>
                <StudyPlanDataProvider
                  planRef={db.doc(`/users/${studentId}/study-plan/plan`)}
                  load={hasOpenedStudyPlan}
                >
                  <StudentStudyPlan
                    studentName={name}
                    studentId={studentId}
                    studentEmail={studentEmail}
                  />
                </StudyPlanDataProvider>
              </StudyPlanStateProvider>
            )}
            {tab === 'settings' && (
              <StudentSettings
                studentId={studentId}
                studentEmail={studentEmail}
              />
            )}
          </div>
        </DeckContentProvider>
      )}
    </AppLayout>
  );
};
