import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { captureException } from '@sentry/browser';
import { useUser } from '@21st-night/core';
import UserContext from './userContext';
import userApi from './user';

const RECENT_STREAK_LENGTH = 5;

// The date of the recent streak period's
// first day at midnight.
const recentStreakStart = new Date(
  Date.now() - (RECENT_STREAK_LENGTH - 1) * 24 * 60 * 60 * 1000,
);
recentStreakStart.setHours(0, 0, 0, 0);

function UserProvider({ children }) {
  const user = useUser();

  function logError({ loading, loadingStreak, loadingDecks }) {
    if (loading || loadingStreak || loadingDecks) {
      captureException(
        new Error(
          `Timeout: user: ${loading}, streak: ${loadingStreak}, decks: ${loadingDecks}, uid: ${user.id}`,
        ),
      );
    }
  }

  function hasSeenMessage(message) {
    return !!user.messaging && user.messaging.includes(message);
  }

  function setMessageSeen(message) {
    if (!hasSeenMessage(message)) {
      return user.setMessageSeen(message);
    }

    return new Promise(resolve => resolve());
  }

  const userDataRef = useRef(user);
  userDataRef.current = user;

  useEffect(() => {
    const timeout = setTimeout(() => logError(userDataRef.current), 15000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider
      value={{ ...userApi, ...user, hasSeenMessage, setMessageSeen }}
    >
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProvider;
