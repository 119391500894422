import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { LoadingIndicator } from '@21st-night/web';

interface PageLoaderProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * If `true`, app bar padding will be applied.
   */
  withAppBar?: boolean;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
    },
    appBarSpacer: {
      ...theme.mixins.toolbar,
    },
  }),
);

const PageLoader: React.FC<PageLoaderProps> = ({
  className: classNameProp,
  withAppBar,
  ...other
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, classNameProp)} {...other}>
      {withAppBar && <div className={classes.appBarSpacer} />}
      <LoadingIndicator size={32} />
      {withAppBar && <div className={classes.appBarSpacer} />}
    </div>
  );
};

export default PageLoader;
