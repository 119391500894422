import { analytics as firebaseAnalytics } from './firebase';

const isProduction = process.env.NODE_ENV === 'production';

function logEvent(event, data) {
  if (isProduction) {
    firebaseAnalytics.logEvent(event, data);
  } else {
    console.log('Analytics event:', event, data);
  }
}

const analytics = {
  logEvent,
};

export default analytics;
