/* eslint-disable @typescript-eslint/unbound-method */
import React, { useCallback, useState } from 'react';
import { useUser } from '@21st-night/core';
import { incrementStatistic } from '@21st-night/gamification';
import {
  useStudyPlan,
  StudyPlanItem,
  generateStudyPlanSubItem,
} from '@21st-night/study-plan-web';
import {
  StudyPlanItemDraggable as StudyPlanItemComponent,
  StudyPlanItemFormContainer,
} from '@21st-night/web';
import { useFirebase } from '@21st-night/utils-web';
import { StudyPlanSubItemContainer } from './StudyPlanSubItemContainer';

export interface StudyPlanItemContainerProps {
  background?: 'paper' | 'default';
  index: number;
  isDragging: boolean;
  item: StudyPlanItem;
}

export const StudyPlanItemContainer: React.FC<StudyPlanItemContainerProps> = ({
  item,
  index,
  isDragging,
  background,
}) => {
  const { db } = useFirebase();
  const user = useUser();
  const plan = useStudyPlan();
  const [edit, setEdit] = useState(false);
  const [newSubitem, setNewSubitem] = useState(false);

  const onClick = useCallback(() => {
    plan.toggleItemDone(item);
    if (!item.donePreviously) {
      incrementStatistic(db, user.id as string, 'items-completed');
    }
  }, [plan, item, user.id, db]);

  const onClickDelete = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      plan.deleteItem(item);
    },
    [plan, item],
  );

  const onClickEdit = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setEdit(true);
  }, []);

  const onClickAddSubItem = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setNewSubitem(true);
  }, []);

  const closeEditForm = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setEdit(false);
  }, []);

  const closeNewSubItemForm = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setNewSubitem(false);
  }, []);

  const handleSubmitEdit = useCallback(
    (title: string) => {
      plan.updateItem(item.id, { title });
      setEdit(false);
    },
    [plan, item],
  );

  const handleSubmitNewSubItem = useCallback(
    (title: string) => {
      const subItem = generateStudyPlanSubItem(item, title);
      plan.addSubItem(subItem);
      setNewSubitem(false);
    },
    [plan, item],
  );

  const subItems = item.subItems
    .filter(id => !!plan.items[id])
    .map(id => plan.items[id]);

  return (
    <>
      {!edit && (
        <StudyPlanItemComponent
          background={background}
          key={item.id}
          index={index}
          id={item.id}
          isDragging={isDragging}
          title={item.title}
          done={item.done}
          onClickDelete={onClickDelete}
          onClickEdit={onClickEdit}
          onClick={onClick}
          onClickAddSubItem={onClickAddSubItem}
        >
          {subItems.map(subItem => (
            <StudyPlanSubItemContainer item={subItem} background={background} />
          ))}
        </StudyPlanItemComponent>
      )}
      {edit && (
        <StudyPlanItemFormContainer
          key={item.id}
          placeholder="Item"
          initialValue={item.title}
          onClickCancel={closeEditForm}
          onSubmit={handleSubmitEdit}
        >
          {subItems.map(subItem => (
            <StudyPlanSubItemContainer item={subItem} background={background} />
          ))}
        </StudyPlanItemFormContainer>
      )}
      {newSubitem && (
        <StudyPlanItemFormContainer
          placeholder="New subitem"
          onClickCancel={closeNewSubItemForm}
          onSubmit={handleSubmitNewSubItem}
        />
      )}
    </>
  );
};
