import React, { useState, useEffect } from 'react';
import {
  AuthenticationForm,
  EmailAuthenticationFormView,
  EmailAuthenticationFormFieldValues,
  EmailAuthenticationFormFieldErrors,
} from '@21st-night/authentication-web';
import { DemoAccountLoadingOverlay } from '../../../../components/DemoAccountLoadingOverlay';
import { auth } from '../../../../libs/firebase';
import analytics from '../../../../libs/analytics';
import user from '../../../../api/user';

interface AuthenticationContainerProps {
  onAuthenticated: () => void;
}

interface FirebaseAuthError {
  code: string;
  message: string;
}

/**
 * Performs basic validation of form field values returning
 * an object containing an error message for each field which
 * failed validation.
 */
export function validate(
  view: EmailAuthenticationFormView,
  values: EmailAuthenticationFormFieldValues,
): [boolean, EmailAuthenticationFormFieldErrors] {
  let valid = true;
  const errors: EmailAuthenticationFormFieldErrors = {};

  if (!values.email) {
    errors.email = 'Required';
    valid = false;
  }

  if (view === 'signup' && !values.terms) {
    errors.terms = 'Required';
    valid = false;
  }

  if (view !== 'forgot-password' && !values.password) {
    errors.password = 'Required';
    valid = false;
  }

  if (view === 'signup') {
    if (!values.repeatPassword) {
      errors.repeatPassword = 'Required';
      valid = false;
    } else if (values.repeatPassword !== values.password) {
      errors.repeatPassword = 'Passwords do not match';
      valid = false;
    }
  }

  return [valid, errors];
}

/**
 * Takes a Firebase auth Error object and returns an
 * error object with the error set to the appropriate
 * form field, or 'other' if the error is not related
 * to a specific field.
 */
export function firebaseError(error: FirebaseAuthError) {
  const errors: EmailAuthenticationFormFieldErrors = {};
  const emailErrors = ['auth/invalid-email', 'auth/email-already-in-use'];
  const passwordErrors = ['auth/weak-password'];

  if (emailErrors.includes(error.code)) {
    errors.email = error.message;
  } else if (passwordErrors.includes(error.code)) {
    errors.password = error.message;
  } else {
    errors.other = error.message;
  }

  return errors;
}

export const AuthenticationContainer: React.FC<AuthenticationContainerProps> = ({
  onAuthenticated,
}) => {
  const [passwordRecoveryEmail, setPasswordRecoveryEmail] = useState('');
  const [loadingDemo, setLoadingDemo] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState<EmailAuthenticationFormFieldErrors>({});
  const [values, setValues] = useState<EmailAuthenticationFormFieldValues>({
    email: '',
    password: '',
    repeatPassword: '',
    terms: false,
  });

  // Watch for auth state change
  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user && onAuthenticated) {
        onAuthenticated();
      }
    });
  }, [onAuthenticated, loadingDemo]);

  function handleEmailFormFieldChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const { name, value, checked } = event.target;
    if (name === 'terms') {
      setValues(vals => ({ ...vals, terms: checked }));
    } else {
      setValues(vals => ({ ...vals, [name]: value }));
    }
  }

  async function handleEmailFormSubmit(
    view: EmailAuthenticationFormView,
    values: EmailAuthenticationFormFieldValues,
  ) {
    const [valid, fieldErrors] = validate(view, values);

    // Disable the form and reset errors
    setDisabled(true);
    setErrors({});

    if (valid) {
      try {
        if (view === 'login') {
          // Attempt to log the user in
          await user.logInWithEmailAndPassword(values.email, values.password);
          // Log the event to analytics
          analytics.logEvent('login', {
            method: 'email',
          });
        } else if (view === 'signup') {
          // Attempt to sign up the user
          await user.signUpWithEmailAndPassword(values.email, values.password);
          // Log the event to analytics
          analytics.logEvent('sign_up', {
            method: 'email',
          });
        } else if (view === 'forgot-password') {
          // Attempt to send password recovery email
          await user.sendPasswordResetEmail(values.email);
          // Log the event to analytics
          analytics.logEvent('forgot_password', {
            method: 'email',
          });
          // Password recovery email sent
          setPasswordRecoveryEmail(values.email);
        }
      } catch (error) {
        // Set server side errors
        setErrors(firebaseError(error));
      }
      // Re-enable the form
      setDisabled(false);
    } else {
      // Re-enable the form and display validation errors
      setDisabled(false);
      setErrors(fieldErrors);
    }
  }

  async function handleClickDemoButton() {
    setLoadingDemo(true);
    await user.signInAnonymously();
  }

  if (loadingDemo) {
    return <DemoAccountLoadingOverlay />;
  }

  return (
    <AuthenticationForm
      emailFormDisabled={disabled}
      onClickGoogleButton={user.logInWithGoogle}
      onClickAppleButton={user.logInWithApple}
      emailFormErrors={errors}
      emailFormValues={values}
      onEmailFormFieldChange={handleEmailFormFieldChange}
      passwordRecoveryEmail={passwordRecoveryEmail}
      onSubmitEmailForm={handleEmailFormSubmit}
      onClickDemoButton={handleClickDemoButton}
    />
  );
};
