import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
      '&::after': {
        content: '""',
        flex: 'auto',
      },
    },
  },
}));

function DeckShareOptions({ className: classNameProp, children, ...other }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, classNameProp)} {...other}>
      {children}
    </div>
  );
}

DeckShareOptions.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default DeckShareOptions;
