/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState, useCallback } from 'react';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse } from '@material-ui/core';
import { useDeck } from '@21st-night/deck-web';
import { useUser } from '@21st-night/core';
import { isDocumentEmpty, deserializeDocument } from '@21st-night/editor-web';
import { DeckContextValue } from '@21st-night/core/lib/providers/DeckProvider';
import {
  OnboardingBannerContainer,
  OnboardingCompletedDialog,
  OnboardingContentType,
  useOnboarding,
} from '@21st-night/onboarding-web';

import { toDate } from '@21st-night/utils-web';
import { CoachingDialog } from '../../../components/CoachingDialog';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: theme.spacing(2),
  },
  section: {
    marginTop: theme.spacing(3),
  },
}));

export const DeckOnboarding: React.FC<{ legacyDeck: DeckContextValue }> = ({
  legacyDeck,
}) => {
  const classes = useStyles();
  const user = useUser();
  const deck = useDeck();
  const onboarding = useOnboarding();
  const [coachingInfoDialogOpen, setCoachingInfoDialogOpen] = useState(false);

  let onboardingVariant: OnboardingContentType = user.segmentation
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      user.segmentation.contentType
    : 'SKILL';

  switch (onboardingVariant as string) {
    case 'a fact-based subject (like history or vocabulary)':
    case 'memorize content (like vocab)':
      onboardingVariant = 'FACT';
      break;
    case 'a skill-based subject (like math or physics)':
    case 'learn a new skill (like algebra)':
      onboardingVariant = 'SKILL';
      break;
    case 'a combination (like a foreign language)':
    case 'both (like a foreign language)':
      onboardingVariant = 'MIXED';
      break;
    default:
  }

  useEffect(() => {
    if (
      user.onboarding &&
      !user.onboarding.completed &&
      user.onboarding.completedSteps &&
      !user.onboarding.completedSteps.includes('check-deck')
    ) {
      onboarding.completeStep('check-deck');
    }

    if (
      user.onboarding &&
      !user.onboarding.completed &&
      user.onboarding.completedSteps &&
      !user.onboarding.completedSteps.includes('create-note')
    ) {
      if (deck.notes.length) {
        let hasContent = false;
        deck.notes.forEach(note => {
          if (!isDocumentEmpty(deserializeDocument(note.content))) {
            hasContent = true;
          }
        });

        // ARE deck has study plan as note
        if (onboardingVariant === 'ARE' && deck.notes.length === 1) {
          hasContent = false;
        }

        if (hasContent) {
          onboarding.completeStep('create-note');
        }
      }
    }

    if (
      user.onboarding &&
      !user.onboarding.completed &&
      user.onboarding.completedSteps &&
      !user.onboarding.completedSteps.includes('create-card') &&
      deck.cards.length > 0
    ) {
      const newCards = deck.cards.filter(
        card =>
          dayjs(toDate(card.createdAt)).diff(
            toDate(deck.createdAt),
            'seconds',
          ) > 10,
      );

      if (newCards.length) {
        onboarding.completeStep('create-card');
      }
    }

    if (
      user.onboarding &&
      !user.onboarding.completed &&
      user.onboarding.completedSteps &&
      !user.onboarding.completedSteps.includes('study-deck')
    ) {
      const studiedCards = deck.cards.filter(card => card.lastReview);

      if (studiedCards.length) {
        onboarding.completeStep('study-deck');
      }
    }
  }, [
    onboarding,
    user.onboarding,
    legacyDeck.lastReview,
    deck.notes,
    deck.cards,
    deck.createdAt,
  ]);

  const finishOnboarding = useCallback(() => {
    onboarding.completeOnboarding();
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (user.segmentation && user.segmentation.method === 'self-study') {
      setCoachingInfoDialogOpen(true);
    }
  }, [user.segmentation, onboarding]);

  const closeCoachingInfoDialog = useCallback(() => {
    user.setMessageSeen('coaching-info');
    setCoachingInfoDialogOpen(false);
  }, [user]);

  if (!coachingInfoDialogOpen && user.onboarding && user.onboarding.completed) {
    return <div />;
  }

  return (
    <div className={classes.root}>
      {user.onboarding && (
        <Collapse
          in={
            user.onboarding &&
            !user.onboarding.completed &&
            user.onboarding.completedSteps &&
            user.onboarding.completedSteps.length < 4
          }
        >
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            <OnboardingBannerContainer />
          </div>
        </Collapse>
      )}
      {user.onboarding && (
        <OnboardingCompletedDialog
          open={
            user.onboarding &&
            !user.onboarding.completed &&
            user.onboarding.completedSteps &&
            user.onboarding.completedSteps.length === 4
          }
          onboardingType={onboardingVariant}
          onClickClose={finishOnboarding}
          onClose={finishOnboarding}
        />
      )}
      {user.onboarding && (
        <CoachingDialog
          variant={onboardingVariant}
          open={coachingInfoDialogOpen}
          onClose={closeCoachingInfoDialog}
        />
      )}
    </div>
  );
};
