import React from 'react';
import dayjs from 'dayjs';
import { useDeck } from '@21st-night/deck-web';
import { useHistory } from 'react-router-dom';
import { useReview } from '@21st-night/review-web';
import {
  DeckPrimaryActionButton,
  DeckPrimaryActionButtonProps,
} from './DeckPrimaryActionButton';

export type DeckStudyButtonProps = Omit<DeckPrimaryActionButtonProps, 'label'>;

export const DeckStudyButton: React.FC<DeckStudyButtonProps> = ({
  ...other
}) => {
  const deck = useDeck();
  const history = useHistory();
  const review = useReview();

  const filteredCards = deck.filter(deck.cards);
  const dueCards = filteredCards.filter(
    card =>
      !card.retired &&
      (!card.nextReview ||
        (card.nextReview &&
          (dayjs(card.nextReview).isBefore(new Date()) ||
            dayjs(card.nextReview).isSame(new Date(), 'date')))),
  );

  let label = dueCards.length
    ? `Study Deck (${dueCards.length} card${dueCards.length === 1 ? '' : 's'})`
    : 'No cards due today';

  if (deck.filters.length && dueCards.length) {
    label = `Study Filtered (${dueCards.length} card${
      dueCards.length === 1 ? '' : 's'
    })`;
  }

  function openReview() {
    review.startReviewSession(dueCards.map(card => card.id));
    history.push(`/deck/${deck.id}/review`);
  }

  return (
    <DeckPrimaryActionButton
      label={label}
      onClick={openReview}
      disabled={!dueCards.length}
      tooltip="21st Night works on a spaced repetition algorithm, assigning you cards to study just as you're about to forget them. If you want to study a card before it's due, click on show answer!"
      style={{ cursor: 'pointer' }}
      {...other}
    />
  );
};
