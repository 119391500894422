/* eslint-disable no-param-reassign */
import { action, thunk } from 'easy-peasy';
import user from '../../api/user';

const onboardingModel = {
  // State
  initialized: false,
  completed: false,
  currentStep: null,

  // Actions
  initialize: action(
    (state, payload = { completed: true, currentStep: null }) => {
      state.completed = payload.completed;
      state.currentStep = payload.currentStep;
      state.variant = payload.variant;
      state.initialized = true;
    },
  ),
  advanced: action((state, payload) => {
    state.currentStep = payload.step;
    state.completed = payload.completed;
  }),
  advance: thunk(async (actions, payload) => {
    actions.advanced(payload);

    user.update({
      'onboarding.currentStep': payload.step,
      'onboarding.completed': payload.completed || false,
    });
  }),
};

export default onboardingModel;
