import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@21st-night/ui';
import { AddStudyPlanButton, StudyPlanTemplateCard } from '@21st-night/web';
import { useStudyPlanTemplates } from '@21st-night/study-plan-web';
import { useHistory } from 'react-router-dom';

export interface StudentCreateStudyPlanProps {
  studentEmail: string;
  studentId: string;
  studentName: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  helperText: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  templates: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > :not(:first-child)': {
      marginLeft: () => theme.spacing(3),
      marginBottom: () => theme.spacing(3),
    },
    '& > :first-child': {
      marginBottom: () => theme.spacing(3),
    },
  },
}));

export const StudentCreateStudyPlan: React.FC<StudentCreateStudyPlanProps> = ({
  studentName,
  studentEmail,
  studentId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { templates } = useStudyPlanTemplates();

  const createStudyPlan = () => {
    history.push(`/create-study-plan/custom/${studentId}/${studentEmail}`);
  };

  const createStudyPlanFromTemplate = (id: string) => {
    history.push(
      `/create-study-plan/template/${studentId}/${studentEmail}/${id}`,
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" className={classes.helperText}>
        {studentName} does not yet have a study plan. You can assign them a
        study plan or create a custom one.
      </Typography>
      <div className={classes.templates}>
        {templates.map(template => (
          <StudyPlanTemplateCard
            template={template}
            onClick={() => createStudyPlanFromTemplate(template.id)}
          />
        ))}
        <AddStudyPlanButton onClick={createStudyPlan} />
      </div>
    </div>
  );
};
