import { createStore } from 'easy-peasy';
import onboardingModel from './features/onboarding/onboardingModel';
import subscriptionModel from './features/subscription/subscriptionModel';

const store = createStore({
  onboarding: onboardingModel,
  subscription: subscriptionModel,
});

export default store;
