import React, { useMemo } from 'react';
import {
  StudyPlanProvider as StudyPlanDataProvider,
  StudyPlanStateProvider,
} from '@21st-night/study-plan-web';
import { useFirebase, useUser } from '@21st-night/core';

export const StudyPlanProvider: React.FC = ({ children }) => {
  const { db } = useFirebase();
  const user = useUser();

  const planRef = useMemo(() => db.doc(`users/${user.id}/study-plan/plan`), [
    db,
    user.id,
  ]);

  if (!user.id || user.hasRole('tutor')) {
    return <>{children}</>;
  }

  return (
    <StudyPlanStateProvider>
      <StudyPlanDataProvider planRef={planRef} load={!!user.hasStudyPlan}>
        {children}
      </StudyPlanDataProvider>
    </StudyPlanStateProvider>
  );
};
