/**
 * Finds all emails addresses in a given string
 * returning them as an array.
 *
 * @param {string} string The string to search through.
 *
 * @returns {array} Array of email addresses.
 */
function getEmailsFromString(string) {
  if (!string) {
    return [];
  }

  return (
    string.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi) || []
  );
}

export default getEmailsFromString;
