import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@21st-night/styles';
import { Add } from '@21st-night/icons';
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  LoadingIndicator,
} from '@21st-night/ui';
import {
  StudyPlan,
  StudyPlanItem,
  generateStudyPlanTask,
  generateStudyPlanItems,
  generateStudyPlanDays,
  addStudyPlanItemsToDays,
  createStudyPlanTask,
  createStudyPlan,
  createStudyPlanItems,
  createStudyPlanDays,
  useWatchStudyPlanTasks,
  TaskFormContainer,
  DayOfTheWeekSelect,
  StudyPlanPreview,
  StudyPlanDay,
} from '@21st-night/study-plan-web';
import { useFirebase } from '@21st-night/utils-web';

export type View = 'edit' | 'preview' | 'loading';

export interface StudyPlanCreatorProps {
  endDate: Date;
  onCreated: (days: StudyPlanDay[], items: StudyPlanItem[]) => void;
  onStartLoading?: () => void;
  startDate: Date;
  userId: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bottomSpacer: {
    padding: 100,
  },
  title: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
  subtitle: {
    textAlign: 'center',
    display: 'block',
    marginBottom: theme.spacing(4),
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: theme.palette.text.secondary,
  },
  appBar: {
    bottom: 0,
    top: 'auto',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
  },
  preview: {
    maxWidth: 600,
    width: '100%',
  },
  loadingView: {
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingIndicator: {
    marginBottom: theme.spacing(2),
  },
}));

const StudyPlanCreator: React.FC<StudyPlanCreatorProps> = ({
  userId,
  startDate,
  endDate,
  onCreated,
  onStartLoading,
}) => {
  const { db } = useFirebase();
  const classes = useStyles();
  const tasksEndRef = useRef<HTMLDivElement>(null);
  const [view, setView] = useState<View>('edit');
  const [shouldScroll, setShouldScroll] = useState(false);
  const [tasks] = useWatchStudyPlanTasks(db, userId);
  const [previousTaskCount, setPreviousTaskCount] = useState(tasks.length);
  const [plan, setPlan] = useState<StudyPlan>({
    createdAt: new Date(),
    startDate,
    endDate,
    startWeekOn: 0,
    description: null,
  });

  const scrollToBottom = () => {
    if (
      shouldScroll &&
      tasksEndRef.current &&
      tasks.length > previousTaskCount
    ) {
      tasksEndRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (tasks.length) {
      setShouldScroll(true);
    }
    setPreviousTaskCount(tasks.length);
  };

  useEffect(scrollToBottom, [tasks.length]);

  function switchView(view: View): () => void {
    return (): void => {
      window.scrollTo(0, 0);
      setView(view);
    };
  }

  function handleStartWeekOnChange(
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ): void {
    setPlan(currentPlan => ({
      ...currentPlan,
      startWeekOn: event.target.value as number,
    }));
  }

  function handleAddTask(): void {
    const item = generateStudyPlanTask(startDate, endDate);
    createStudyPlanTask(db, userId, item);
  }

  async function handleSubmit(): Promise<void> {
    if (onStartLoading) {
      onStartLoading();
    }
    setView('loading');
    await createStudyPlan(db, userId, plan);
    let days = generateStudyPlanDays(plan.startDate, plan.endDate);
    const items = tasks.reduce(
      (itms, task) => [...itms, ...generateStudyPlanItems(task, days)],
      [] as StudyPlanItem[],
    );
    days = addStudyPlanItemsToDays(days, items);
    await createStudyPlanItems(db, userId, items);
    await createStudyPlanDays(db, userId, days);

    onCreated(days, items);
  }

  return (
    <div className={classes.root}>
      {view === 'edit' && (
        <>
          <Typography gutterBottom variant="h4" className={classes.title}>
            Tasks
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Let&apos;s set up your study plan&apos;s tasks. Create a task type
            below for every type of item you want to include in your plan. You
            can preview the generated study plan by clicking on the Preview tab.
          </Typography>
          <div
            style={{
              marginTop: 16,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography>Start week on</Typography>
            <DayOfTheWeekSelect
              value={plan.startWeekOn}
              onChange={handleStartWeekOnChange}
              style={{ marginLeft: 8 }}
            />
          </div>
          {tasks.map(task => (
            <TaskFormContainer
              userId={userId}
              db={db}
              key={task.id}
              minStartDate={startDate}
              maxEndDate={endDate}
              task={task}
              startWeekOn={plan.startWeekOn}
            />
          ))}
          <div ref={tasksEndRef} />
        </>
      )}
      {view === 'preview' && (
        <>
          <Typography gutterBottom variant="h4" className={classes.title}>
            Preview
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Here&apos;s a preview of your study plan. Once you&apos;re happy
            with it, click on &ldquo;Confirm Study Plan&rdquo; to create it. You
            can always modify it and add more items later.
          </Typography>
          <div className={classes.preview}>
            <StudyPlanPreview plan={plan} tasks={tasks} />
          </div>
        </>
      )}
      {view === 'loading' && (
        <div className={classes.loadingView}>
          <LoadingIndicator size="large" className={classes.loadingIndicator} />
          <Typography variant="h6">Creating study plan...</Typography>
        </div>
      )}
      {view !== 'loading' && (
        <>
          <div className={classes.bottomSpacer} />
          <AppBar color="default" position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              {view === 'edit' && (
                <>
                  <Button
                    size="large"
                    gutter="end"
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={handleAddTask}
                  >
                    Add a Task
                  </Button>
                  <Button
                    size="large"
                    color="primary"
                    onClick={switchView('preview')}
                  >
                    Preview Study Plan
                  </Button>
                </>
              )}
              {view === 'preview' && (
                <>
                  <Button
                    size="large"
                    color="primary"
                    gutter="end"
                    onClick={switchView('edit')}
                  >
                    Edit items
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Confirm Study Plan
                  </Button>
                </>
              )}
            </Toolbar>
          </AppBar>
        </>
      )}
    </div>
  );
};

export default StudyPlanCreator;
