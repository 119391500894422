import React from 'react';
import { GoldCounterProvider } from '@21st-night/web';
import { useUser } from '@21st-night/core';

export const GamificationProvider: React.FC = ({ children }) => {
  const user = useUser();

  if (!user.id) {
    return <>{children}</>;
  }

  return (
    <GoldCounterProvider value={user.gold || 0}>{children}</GoldCounterProvider>
  );
};
