import * as React from 'react';
import { Dayjs } from 'dayjs';
import { motion, MotionProps } from 'framer-motion';
import { DatePicker, Button } from '@21st-night/ui';
import { makeStyles } from '@material-ui/core/styles';

export interface DateSelectProps extends MotionProps {
  inputFormat?: string;
  maxDate?: Date;
  onChange: (date: unknown) => void;
  onClickNext: () => void;
  value: Dayjs;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginLeft: 8,
  },
}));

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const DateSelect: React.FC<DateSelectProps> = ({
  onChange,
  value,
  inputFormat = 'D MMMM, YYYY',
  maxDate,
  onClickNext,
  ...other
}) => {
  const classes = useStyles();

  return (
    <motion.div variants={variants} {...other} className={classes.root}>
      <DatePicker
        disablePast
        maxDate={maxDate}
        inputFormat={inputFormat}
        {...{ onChange, value }}
      />
      <Button
        gutter="start"
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={onClickNext}
      >
        Next
      </Button>
    </motion.div>
  );
};

export default DateSelect;
