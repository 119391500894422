import { useReducer } from 'react';

function reducer(state, action) {
  switch (action.type) {
    case 'set-value':
      return { ...state, [action.payload.name]: action.payload.value };
    case 'clear':
      return { ...action.payload };
    case 'set-values':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}

/**
 * Provides an object containing form field values
 * and a function to set the field values using an
 * input's onChange callback, and a function to
 * clear the values.
 *
 * The key of each value is the name attribute of
 * the input field (taken from the event object).
 *
 * @param {object} initialValues The inital field values.
 */
const useForm = (initialValues = {}) => {
  const [values, dispatch] = useReducer(reducer, initialValues);

  function onFieldChange(e, checked) {
    const name = e.target.getAttribute('name');
    let value;

    if (e.target.getAttribute('type') === 'checkbox') {
      value = checked;
    } else {
      ({ value } = e.target);
    }

    dispatch({ type: 'set-value', payload: { name, value } });
  }

  function clear() {
    dispatch({ type: 'clear', payload: initialValues });
  }

  function setValues(newValues) {
    dispatch({ type: 'set-values', payload: newValues });
  }

  return [values, onFieldChange, clear, setValues];
};

export default useForm;
