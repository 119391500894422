import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EmailList from '../DeckShareEmailList';

export const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    maxWidth: 340,
    margin: '0 auto',
  },
  title: {
    textAlign: 'center',
  },
}));

function DeckShareTeachSuccess({
  className: classNameProp,
  children,
  emails,
  ...other
}) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, classNameProp)} {...other}>
      <Typography gutterBottom variant="h5" className={classes.title}>
        Success
      </Typography>
      <Typography gutterBottom variant="body2" color="textSecondary">
        <strong>
          {emails.length} {emails.length === 1 ? 'person' : 'people'}
        </strong>{' '}
        {emails.length === 1 ? 'has' : 'have'} been sent an invitation to this
        deck.
      </Typography>
      <EmailList emails={emails} />
    </div>
  );
}

DeckShareTeachSuccess.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /**
   * List of emails the deck was shared with.
   */
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
};

DeckShareTeachSuccess.defaultProps = {};

export default DeckShareTeachSuccess;
