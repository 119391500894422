import { captureException } from '@sentry/browser';
import { db, firebase } from '../../libs/firebase';
import cards from '../cards';
import user from '../user';

const decks = {
  /**
   * Fetches a deck by id.
   *
   * @param {string} id The deck id.
   *
   * @returns {Promise}
   */
  get: id => {
    return new Promise(async (resolve, reject) => {
      let deck;
      const categories = [];
      try {
        await Promise.all([
          db
            .collection('decks')
            .doc(id)
            .get()
            .then(doc => {
              deck = doc;
            }),
          db
            .collection('decks')
            .doc(id)
            .collection('categories')
            .get()
            .then(docs => {
              docs.forEach(doc => {
                categories.push({ id: doc.id, ...doc.data() });
              });
            }),
        ]);
      } catch (error) {
        captureException(error);
        reject(error);
      }

      resolve({ id: deck.id, ...deck.data(), categories });
    });
  },

  /**
   * Creates a new deck.
   *
   * @param {object} data The deck data.
   *
   * @returns {Promise}
   */
  create: (userId, data) => {
    return db
      .collection('users')
      .doc(userId)
      .collection('decks')
      .add({
        createdAt: new Date(),
        cards: {},
        notes: {},
        categories: [],
        subcategories: [],
        ...data,
      });
  },

  /**
   * Updates a deck by id.
   *
   * @param {string} id   The deck document ID.
   * @param {object} data The update data.
   *
   * @returns {Promise}
   */
  update: (id, userId, data) => {
    return db
      .collection('users')
      .doc(userId)
      .collection('decks')
      .doc(id)
      .update(data);
  },

  /**
   * Deletes a deck by id.
   *
   * @param {string} id The deck document ID.
   *
   * @returns {Promise}
   */
  delete: (userId, deckId) => {
    return Promise.all([
      db
        .collection('users')
        .doc(userId)
        .collection('decks')
        .doc(deckId)
        .delete(),
      db
        .collection('users')
        .doc(userId)
        .update({
          deckIds: firebase.firestore.FieldValue.arrayRemove(deckId),
          teachingDecks: firebase.firestore.FieldValue.arrayRemove(deckId),
          studentDecks: firebase.firestore.FieldValue.arrayRemove(deckId),
        }),
    ]);
  },

  /**
   * Creates a new card and adds it to the deck.
   *
   * @param {string} id       The deck document ID.
   * @param {object} cardData The card data.
   *
   * @returns {Promise}
   */
  createCard: async (deckId, userId, cardData) =>
    new Promise(async (resolve, reject) => {
      try {
        const card = await cards.create({
          ...cardData,
          deck: deckId,
          createdAt: new Date(),
        });

        user.updateLastActivity();

        resolve(card);
      } catch (error) {
        reject(error);
      }
    }),

  /**
   * Creates a new card and adds it to the deck.
   *
   * @param {string} id       The deck document ID.
   * @param {object} cardData The card data.
   *
   * @returns {Promise}
   */
  createPersonalCard: async (deckId, userId, cardData) =>
    new Promise(async (resolve, reject) => {
      try {
        const card = await cards.create({
          ...cardData,
          userId,
          deck: deckId,
          decks: [deckId],
          createdAt: new Date(),
        });
        await db
          .collection('decks')
          .doc(deckId)
          .collection('users')
          .doc(userId)
          .update({
            cards: firebase.firestore.FieldValue.arrayUnion(card.id),
          });

        resolve(card);
      } catch (error) {
        reject(error);
      }
    }),

  /**
   * Creates a new category for the deck.
   *
   * @param {string} id           The deck document ID.
   * @param {object} categoryData The category data.
   *
   * @returns {Promise}
   */
  createCategory: async (deckId, userId, name) =>
    new Promise(async (resolve, reject) => {
      try {
        await db
          .collection('users')
          .doc(userId)
          .collection('decks')
          .doc(deckId)
          .update({
            categories: firebase.firestore.FieldValue.arrayUnion(name),
          });
        resolve(name);
      } catch (error) {
        reject(error);
      }
    }),

  /**
   * Creates a new subcategory for the deck.
   *
   * @param {string} id           The deck document ID.
   * @param {object} categoryData The category data.
   *
   * @returns {Promise}
   */
  createSubcategory: async (deckId, userId, name) =>
    new Promise(async (resolve, reject) => {
      try {
        await db
          .collection('users')
          .doc(userId)
          .collection('decks')
          .doc(deckId)
          .update({
            subcategories: firebase.firestore.FieldValue.arrayUnion(name),
          });
        resolve(name);
      } catch (error) {
        reject(error);
      }
    }),

  /**
   * Updates a deck category.
   *
   * @param {string} deckId       The deck document ID.
   * @param {string} categoryId   The category document ID.
   * @param {object} categoryData The category data.
   *
   * @returns {Promise}
   */
  updateCategory: async (deckId, categoryId, categoryData) =>
    db
      .collection('decks')
      .doc(deckId)
      .collection('categories')
      .doc(categoryId)
      .update(categoryData),

  /**
   * Deletes a deck category.
   *
   * @param {string} deckId       The deck document ID.
   * @param {string} categoryId   The category document ID.
   *
   * @returns {Promise}
   */
  deleteCategory: async (deckId, categoryId) =>
    db
      .collection('decks')
      .doc(deckId)
      .collection('categories')
      .doc(categoryId)
      .delete(),

  logEvent: (userId, deckId, data) =>
    db
      .collection('users')
      .doc(userId)
      .collection('decks')
      .doc(deckId)
      .collection('analytics-events')
      .add(data),
};

window.decks = decks;

export default decks;
