import React from 'react';
import {
  Deck,
  DeckFilter,
  DeckFilterDateInput,
  DeckProvider,
  DeckConsumer,
  useDeckContent,
} from '@21st-night/deck-web';
import { Spaced } from '@21st-night/ui';
import { toDate, useFirebase } from '@21st-night/utils-web';
import { AnalyticsView } from '@21st-night/analytics-web';
import { makeStyles } from '@21st-night/styles';

export type StudentAnalyticsProps = {
  deck: Deck;
  studentId: string;
};

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1000,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 300,
    paddingTop: theme.spacing(1),
  },
  filtering: {
    marginBottom: theme.spacing(2),
  },
}));

export const StudentAnalytics: React.FC<StudentAnalyticsProps> = ({
  deck,
  studentId,
}) => {
  const { db } = useFirebase();
  const classes = useStyles();
  const content = useDeckContent();

  return (
    <div className={classes.root}>
      <DeckProvider
        docRef={db.doc(`/users/${studentId}/decks/${deck.id}`)}
        cards={content.cards}
        notes={content.notes}
        deck={deck}
      >
        <DeckConsumer>
          {providedDeck => (
            <>
              <Spaced className={classes.filtering}>
                <DeckFilter />
                {providedDeck.filters.find(
                  item => item.value === 'date-added',
                ) && <DeckFilterDateInput />}
              </Spaced>
              <AnalyticsView cards={providedDeck.filter(providedDeck.cards)} />
            </>
          )}
        </DeckConsumer>
      </DeckProvider>
    </div>
  );
};
