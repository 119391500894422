import React, { useCallback } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useStudyPlan } from '@21st-night/study-plan-web';

export const StudyPlanDragDropContext: React.FC = ({ children }) => {
  const plan = useStudyPlan();

  const handleDopItem = useCallback(
    ({ draggableId, source, destination }: DropResult) => {
      const item = plan.items[draggableId];

      if (!destination || !item) {
        return;
      }

      if (source.droppableId !== destination.droppableId) {
        // Item was moved to another day
        plan.moveItem(item, destination.droppableId, destination.index);
      } else {
        // Item was moved within a day
        plan.sortItem(item, destination.index);
      }
    },
    [plan],
  );

  return (
    <DragDropContext onDragEnd={handleDopItem}>{children}</DragDropContext>
  );
};
