import { auth, db, firebase, functions } from '../../libs/firebase';
import analytics from '../../libs/analytics';
import decks from '../decks';

// Initialize reactivateSubscription Firebase function
export const reactivateSubscription = functions.httpsCallable(
  'reactivateSubscription',
);
// Initialize cancelSubscription Firebase function
export const cancelSubscription = functions.httpsCallable('cancelSubscription');
// Initialize updatePaymentDetails Firebase function
export const updatePaymentDetails = functions.httpsCallable(
  'updatePaymentDetails',
);
// Initialize applyCoupon Firebase function
export const applyCoupon = functions.httpsCallable('applyCoupon');
// Initialize changeBillingEmail Firebase function
export const changeBillingEmail = functions.httpsCallable('changeBillingEmail');
// Initialize unsubscribeFromEmails Firebase function
export const unsubscribeFromEmails = functions.httpsCallable(
  'unsubscribeFromEmails',
);
// Initialize acceptDeckInvitation Firebase function
export const acceptDeckInvitation = functions.httpsCallable(
  'acceptDeckInvitation',
);
// Initialize acceptDeckStudentInvitation Firebase function
export const acceptDeckStudentInvitation = functions.httpsCallable(
  'acceptDeckStudentInvitation',
);
// Initialize acceptDeckTeacherInvitation Firebase function
export const acceptDeckTeacherInvitation = functions.httpsCallable(
  'acceptDeckTeacherInvitation',
);
// Initialize savePublicDeck Firebase function
export const savePublicDeck = functions.httpsCallable('savePublicDeck');

const user = {
  /**
   * Returns the ID of the currently logged in
   * user, or false if user is not logged in.
   *
   * @returns {string|boolean}
   */
  id: () => auth.currentUser && auth.currentUser.uid,

  /**
   * Returns the true an false if user is not
   * logged in.
   *
   * @returns {boolean}
   */
  isLoggedIn: () => !!auth.currentUser,

  /**
   * Logs the user in using Google oAuth.
   *
   * @returns {Promise}
   */
  logInWithGoogle: () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('email');

    // Log the event to analytics
    analytics.logEvent('login', {
      method: 'google',
    });

    return auth.signInWithPopup(provider);
  },

  /**
   * Logs the user in using Apple oAuth.
   *
   * @returns {Promise}
   */
  logInWithApple: () => {
    const provider = new firebase.auth.OAuthProvider('apple.com');

    // Log the event to analytics
    analytics.logEvent('login', {
      method: 'apple',
    });

    return auth.signInWithPopup(provider);
  },

  /**
   * Logs the user in using an email/password combination.
   *
   * @param {string} email    The user's email address.
   * @param {string} password The user's password.
   *
   * @returns {Promise}
   */
  logInWithEmailAndPassword: (email, password) =>
    auth.signInWithEmailAndPassword(email, password),

  /**
   * Logs the user in anonymously.
   *
   * @returns {Promise}
   */
  signInAnonymously: () => auth.signInAnonymously(),

  /**
   * Creates a new account using an email/password combination.
   *
   * @param {string} email    The user's email address.
   * @param {string} password The user's password.
   *
   * @returns {Promise}
   */
  signUpWithEmailAndPassword: (email, password) =>
    auth.createUserWithEmailAndPassword(email, password),

  /**
   * Logs the user out.
   */
  logout: () => auth.signOut(),

  /**
   * Sends a password recovery email to the given email address.
   *
   * @param {string} email    The user's email address.
   *
   * @returns {Promise}
   */
  sendPasswordResetEmail: email => auth.sendPasswordResetEmail(email),

  /**
   * Updates the currently logged in user's
   * payment method.
   *
   * @param {object} data The new payment method data.
   *
   * @returns {Promise}
   */
  updatePaymentMethod: data => {
    if (!user.id()) {
      throw new Error('User is not logged in');
    }

    return updatePaymentDetails(data);
  },

  /**
   * Applies a coupon the currently logged in user.
   *
   * @param {string} coupon The coupon ID.
   *
   * @returns {Promise}
   */
  applyCoupon: coupon => {
    if (!user.id()) {
      throw new Error('User is not logged in');
    }

    return applyCoupon({ coupon });
  },

  /**
   * Updates the currently logged in user's
   * billing email.
   *
   * @param {string} email The new billing email.
   *
   * @returns {Promise}
   */
  changeBillingEmail: email => {
    if (!user.id()) {
      throw new Error('User is not logged in');
    }

    return changeBillingEmail({ email });
  },

  /**
   * Updates the currently logged in user's
   * user doc.
   *
   * @param {object} data The update data.
   *
   * @returns {Promise}
   */
  update: data => {
    if (!user.id()) {
      throw new Error('User is not logged in');
    }

    return db.collection('users').doc(user.id()).update(data);
  },

  /**
   * Re-activates the user's subscription.
   *
   * @returns {Promise}
   */
  reactivateSubscription: data => {
    if (!user.id()) {
      throw new Error('User is not logged in');
    }

    return reactivateSubscription(data);
  },

  /**
   * Cancels the user's subscription.
   *
   * @returns {Promise}
   */
  cancelSubscription: () => {
    if (!user.id()) {
      throw new Error('User is not logged in');
    }

    return cancelSubscription();
  },

  /**
   * Adds the message ID to the user's
   * messaging property.
   */
  setMessageSeen: id =>
    user.update({ messaging: firebase.firestore.FieldValue.arrayUnion(id) }),
  /**
   * Creates a new deck owned by the user.
   *
   * @param {object} data The deck data.
   *
   * @returns {Promise}
   */
  createDeck: data =>
    new Promise(async (resolve, reject) => {
      try {
        // Create the deck
        const deck = await decks.create(user.id(), {
          roles: ['owner'],
          ...data,
        });
        // Add deck ID to user's decks
        await user.update({
          deckIds: firebase.firestore.FieldValue.arrayUnion(deck.id),
        });

        resolve(deck);
      } catch (error) {
        reject(error);
      }
    }),

  /**
   * Sets a card's progress data on the user.
   *
   * @param {object} data The deck data.
   *
   * @returns {Promise}
   */
  setCardProgress: (cardId, data) => {
    delete data.notStarted;

    return db
      .collection('users')
      .doc(user.id())
      .collection('card-progress')
      .doc(cardId)
      .set({
        proficiency: 0,
        nextReview: new Date(),
        retired: false,
        attempts: [],
        ...data,
      });
  },

  /**
   * Retires a card by id.
   *
   * @param {string} id   The card document ID.
   * @param {object} data The update data.
   *
   * @returns {Promise}
   */
  retireCard: (cardId, deckId) =>
    new Promise(async (resolve, reject) => {
      const ref = db
        .collection('users')
        .doc(user.id())
        .collection('card-progress')
        .doc(cardId);
      let cardProgress = await ref.get();

      try {
        if (cardProgress.exists) {
          cardProgress = await ref.update({ retired: true });
        } else {
          cardProgress = await user.setCardProgress(cardId, {
            retired: true,
            deckId,
          });
        }
      } catch (error) {
        reject(error);
      }

      resolve(cardProgress);
    }),

  /**
   * Unretires a card by id.
   *
   * @param {string} id   The card document ID.
   * @param {object} data The update data.
   *
   * @returns {Promise}
   */
  unretireCard: (cardId, deckId) =>
    new Promise(async (resolve, reject) => {
      const ref = db
        .collection('users')
        .doc(user.id())
        .collection('card-progress')
        .doc(cardId);
      let cardProgress = await ref.get();

      try {
        if (cardProgress.exists) {
          cardProgress = await ref.update({ retired: false });
        } else {
          cardProgress = await user.setCardProgress(cardId, {
            retired: false,
            deckId,
          });
        }
      } catch (error) {
        reject(error);
      }

      resolve(cardProgress);
    }),

  /**
   * Stars a card by id.
   *
   * @param {string} id   The card document ID.
   * @param {object} data The update data.
   *
   * @returns {Promise}
   */
  starCard: (cardId, deckId) =>
    new Promise(async (resolve, reject) => {
      db.collection('users')
        .doc(user.id())
        .collection('decks')
        .doc(deckId)
        .update({ [`cards.${cardId}.starred`]: true });
      const ref = db
        .collection('users')
        .doc(user.id())
        .collection('card-progress')
        .doc(cardId);
      let cardProgress = await ref.get();

      try {
        if (cardProgress.exists) {
          cardProgress = await ref.update({ starred: true });
        } else {
          cardProgress = await user.setCardProgress(cardId, {
            starred: true,
            deckId,
          });
        }
      } catch (error) {
        reject(error);
      }

      resolve(cardProgress);
    }),

  /**
   * Unstars a card by id.
   *
   * @param {string} id   The card document ID.
   * @param {object} data The update data.
   *
   * @returns {Promise}
   */
  unstarCard: (cardId, deckId) =>
    new Promise(async (resolve, reject) => {
      db.collection('users')
        .doc(user.id())
        .collection('decks')
        .doc(deckId)
        .update({ [`cards.${cardId}.starred`]: false });
      const ref = db
        .collection('users')
        .doc(user.id())
        .collection('card-progress')
        .doc(cardId);
      let cardProgress = await ref.get();

      try {
        if (cardProgress.exists) {
          cardProgress = await ref.update({ starred: false });
        } else {
          cardProgress = await user.setCardProgress(cardId, {
            starred: false,
            deckId,
          });
        }
      } catch (error) {
        reject(error);
      }

      resolve(cardProgress);
    }),

  /**
   * Unsubscribes the user from scheduled emails.
   *
   * @returns {Promise}
   */
  unsubscribeFromEmails: () => {
    if (!user.id()) {
      throw new Error('User is not logged in');
    }

    return unsubscribeFromEmails();
  },

  /**
   * Accepts an invitation to a shared deck,
   * adding it to the user's decks.
   *
   * @param {object} data
   * @param {string} data.deckId       The deck's ID.
   * @param {string} data.invitationId The invitation's ID.
   *
   * @returns {Promise}
   */
  acceptDeckInvitation: async data => {
    if (!user.id()) {
      throw new Error('User is not logged in');
    }

    const invitation = await db
      .collection('share-invitations')
      .doc(data.invitationId)
      .get();

    if (!invitation.exists) {
      throw new Error('Invitation does not exist');
    }

    if (invitation.data().accepted) {
      throw new Error('Invitation already accepted');
    }

    // Log the event to analytics
    analytics.logEvent('accept_deck_invitation');

    if (invitation.data().type === 'student') {
      return acceptDeckStudentInvitation(data);
    }

    if (invitation.data().type === 'teacher') {
      return acceptDeckTeacherInvitation(data);
    }

    return acceptDeckInvitation(data);
  },

  /**
   * Accepts an invitation to a shared deck,
   * adding it to the user's decks.
   *
   * @param {object} data
   * @param {string} data.deckId The deck's ID.
   *
   * @returns {Promise}
   */
  savePublicDeck: data => {
    if (!user.id()) {
      throw new Error('User is not logged in');
    }

    return savePublicDeck(data);
  },

  updateLastActivity: () => user.update({ lastActivity: new Date() }),
};

window.user = user;

export default user;
