import React, { useCallback, useState, Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { use100vh } from 'react-div-100vh';
import { Logo } from '@21st-night/web';
import { OnboardingContentType } from '@21st-night/onboarding-web';
import { AuthenticatedUserContext, useUser } from '@21st-night/user';
import PageLoader from '../PageLoader';
import AppBar, { AppBarProps } from '../AppBar';
import AppDrawer from '../AppDrawer';
import { CoachingDialog } from '../CoachingDialog';

export interface AppLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  appBarContent?: AppBarProps['children'];
  breadcrumbs?: AppBarProps['breadcrumbs'];
  /**
   * If `true`, the content will be
   * horizontally centered.
   */
  centerHorizontally?: boolean;
  /**
   * If `true`, the content will be
   * vertically centered.
   */
  centerVertically?: boolean;
  /**
   * If `true`, the action buttons will not be rendered
   * in the AppBar.
   */
  disableAppBarActions?: boolean;
  /**
   * If `true`, the footer will be fixed
   * to the bottom of the page.
   */
  fixedFooter?: boolean;
  /**
   * Content of the footer rendered
   * below main content.
   */
  footer?: React.ReactNode;
  /**
   * If `true`, a loading indicator will
   * be rendered in place of the content.
   */
  loading?: boolean;
  /**
   * If present, a sidebar will be rendered
   * with this prop as its content/
   */
  sidebar?: React.ReactNode;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.standard,
      }),
    },
    drawerOpen: {
      transform: 'scale(0.95)',
    },
    container: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      flexShrink: 0,
      [theme.breakpoints.up('sm')]: {
        overflowY: 'scroll',
        WebkitOverflowScrolling: 'touch',
      },
    },
    sidebarPlaceholder: {
      width: 300,
      height: '100%',
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.down('md')]: {
        width: 240,
      },
    },
    sidebar: {
      width: 300,
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      overflowY: 'scroll',
      WebkitOverflowScrolling: 'touch',
      backgroundColor: '#ECEFF1',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        width: 240,
      },
    },
    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 0,
      overflowY: 'scroll',
      backfaceVisibility: 'hidden',
      WebkitOverflowScrolling: 'touch',
      [theme.breakpoints.only('xs')]: {
        padding: theme.spacing(0, 2),
      },
    },
    appBarSpacer: {
      ...theme.mixins.toolbar,
    },
    footer: {
      display: 'flex',
    },
    fixedFooter: {
      backgroundColor: theme.palette.background.default,
      boxShadow: '4px 0 2px hsla(0, 0%, 0%, .2)',
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
    },
    withFixedFooter: {
      paddingBottom: 120,
    },
    centerHorizontally: {
      alignItems: 'center',
    },
    centerVertically: {
      justifyContent: 'center',
    },
    footerCenterHorizontally: {
      justifyContent: 'center',
    },
    sidebarLogo: {
      display: 'block',
      margin: theme.spacing(3, 2),
      color: '#9AA5B1',
    },
  }),
);

const AppLayout: React.FC<AppLayoutProps> = ({
  appBarContent,
  breadcrumbs,
  centerHorizontally = false,
  centerVertically = false,
  className: classNameProp,
  children,
  disableAppBarActions = false,
  fixedFooter = false,
  footer,
  loading = false,
  sidebar,
  ...other
}) => {
  const height = use100vh();
  const theme = useTheme();
  const user = useUser() as AuthenticatedUserContext;
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [coachingDialogOpen, setCoachingDialogOpen] = useState(false);

  const openDrawer = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const openCoachingDialog = useCallback(() => {
    setCoachingDialogOpen(true);
  }, []);

  const closeCoachingDialog = useCallback(() => {
    setCoachingDialogOpen(false);
  }, []);

  return (
    <div
      style={{ height: height || '100vh' }}
      className={clsx(
        classes.root,
        { [classes.drawerOpen]: drawerOpen },
        classNameProp,
      )}
      {...other}
    >
      {loading && <PageLoader withAppBar />}

      {!loading && (
        <Fragment>
          {!!sidebar && !isSm && (
            <Fragment>
              <div className={classes.sidebarPlaceholder} />
              <div className={classes.sidebar}>
                <Logo className={classes.sidebarLogo} />
                {sidebar}
              </div>
            </Fragment>
          )}
          <div className={classes.container}>
            <AppBar
              disableLogo={isXs}
              disableActions={disableAppBarActions}
              onClickMenuButton={openDrawer}
              onClickCoachingButton={openCoachingDialog}
              breadcrumbs={breadcrumbs}
            >
              {!isSm && appBarContent}
            </AppBar>
            <div className={classes.appBarSpacer} />
            <div
              className={clsx(classes.content, {
                [classes.centerHorizontally]: centerHorizontally,
                [classes.centerVertically]: centerVertically,
                [classes.withFixedFooter]: fixedFooter,
              })}
            >
              {children}
            </div>
            {footer && (
              <div
                className={clsx(classes.footer, {
                  [classes.footerCenterHorizontally]: centerHorizontally,
                  [classes.fixedFooter]: fixedFooter,
                })}
              >
                {footer}
              </div>
            )}
          </div>
        </Fragment>
      )}
      <AppDrawer open={drawerOpen} onClose={closeDrawer} />
      {user.segmentation && (
        <CoachingDialog
          variant={user.segmentation.contentType as OnboardingContentType}
          open={coachingDialogOpen}
          onClose={closeCoachingDialog}
        />
      )}
    </div>
  );
};

export default AppLayout;
