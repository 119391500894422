import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Button } from '@21st-night/web';

export const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12)',
    maxWidth: 240 + theme.spacing(3),
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
    backgroundColor: theme.palette.background.paper,
    marginTop: 40,
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  avatar: {
    width: 70,
    height: 70,
    marginTop: -35 - theme.spacing(2),
  },
  icon: {
    color: '#FFF',
    height: 35,
    width: 35,
  },
  title: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  description: {
    color: theme.palette.text.secondary,
    flex: 1,
  },
}));

function DeckShareOption({
  className: classNameProp,
  color,
  comingSoon,
  description,
  icon,
  onSelect,
  title,
  ...other
}) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, classNameProp)} {...other}>
      <Avatar className={classes.avatar} style={{ backgroundColor: color }}>
        {React.cloneElement(icon, { className: classes.icon })}
      </Avatar>
      <Typography gutterBottom variant="h5" className={classes.title}>
        {title}
      </Typography>
      <div className={classes.description}>{description}</div>
      {!comingSoon && (
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={onSelect}
        >
          Select
        </Button>
      )}
      {comingSoon && <Typography variant="caption">coming soon</Typography>}
    </div>
  );
}

DeckShareOption.propTypes = {
  className: PropTypes.string,
  /**
   * The color scheme of the option.
   */
  color: PropTypes.string.isRequired,
  comingSoon: PropTypes.bool,
  /**
   * The description of the option.
   */
  description: PropTypes.node.isRequired,
  /**
   * The icon of the option.
   */
  icon: PropTypes.node.isRequired,
  /**
   * Callback fired when the Select button
   * is clicked.
   */
  onSelect: PropTypes.func.isRequired,
  /**
   * The title of the option.
   */
  title: PropTypes.node.isRequired,
};

DeckShareOption.defaultProps = {};

export default DeckShareOption;
