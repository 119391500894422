/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { StoreProvider } from 'easy-peasy';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { LocalizationProvider } from '@material-ui/pickers';
import DayjsUtils from '@material-ui/pickers/adapter/dayjs';
import {
  FirebaseProvider as NewFirebaseProvider,
  DB,
  Auth,
  Storage,
  FirebaseProvider as WebFirebaseProvider,
} from '@21st-night/utils-web';
import { UserProvider } from '@21st-night/user';
import {
  FirebaseProvider,
  UserProvider as LegacyUserProvider,
  UploadsProvider,
} from '@21st-night/core';
import { OnboardingProvider } from './components/OnboardingProvider';
import { GamificationProvider } from './components/GamificationProvider';
import {
  analytics,
  auth,
  db,
  functions,
  storage,
  firebase,
} from './libs/firebase';
import UserPomodoroProvider from './api/user/UserPomodoroProvider';
import store from './store';
import { StudyPlanProvider } from './components/StudyPlanProvider';
import OldUserProvider from './api/user/UserProvider';
import * as serviceWorker from './serviceWorker';
import { Router } from './router';
import theme from './theme';
import Routes from './Routes';
import ErrorBoundary from './components/ErrorBoundary';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `21st-night@${process.env.REACT_APP_VERSION}`,
  });
}

// @ts-ignore
db.arrayRemove = firebase.firestore.FieldValue.arrayRemove;
// @ts-ignore
db.arrayUnion = firebase.firestore.FieldValue.arrayUnion;
// @ts-ignore
db.delete = firebase.firestore.FieldValue.delete;
// @ts-ignore
db.increment = firebase.firestore.FieldValue.increment;

ReactDOM.render(
  <ErrorBoundary>
    <StoreProvider store={store}>
      <NewFirebaseProvider
        auth={(auth as unknown) as Auth}
        db={(db as unknown) as DB}
        functions={functions}
        storage={(storage as unknown) as Storage}
        imageUrl={process.env.REACT_APP_IMGIX_URL as string}
      >
        <WebFirebaseProvider
          auth={(auth as unknown) as Auth}
          db={(db as unknown) as DB}
          functions={functions}
          storage={(storage as unknown) as Storage}
          imageUrl={process.env.REACT_APP_IMGIX_URL as string}
        >
          <FirebaseProvider
            analytics={analytics}
            auth={auth}
            db={db}
            functions={functions}
            storage={storage}
          >
            <UserProvider>
              <LegacyUserProvider>
                <OldUserProvider>
                  <OnboardingProvider>
                    <GamificationProvider>
                      <StudyPlanProvider>
                        <UserPomodoroProvider>
                          <UploadsProvider>
                            <LocalizationProvider dateAdapter={DayjsUtils}>
                              <MuiThemeProvider theme={theme}>
                                <CssBaseline />
                                <Router>
                                  <Routes />
                                </Router>
                              </MuiThemeProvider>
                            </LocalizationProvider>
                          </UploadsProvider>
                        </UserPomodoroProvider>
                      </StudyPlanProvider>
                    </GamificationProvider>
                  </OnboardingProvider>
                </OldUserProvider>
              </LegacyUserProvider>
            </UserProvider>
          </FirebaseProvider>
        </WebFirebaseProvider>
      </NewFirebaseProvider>
    </StoreProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
