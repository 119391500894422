import * as fb from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/performance';
import 'firebase/storage';
import 'firebase/analytics';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const app = fb.initializeApp(config);
export const perf = app.performance();
export const db = app.firestore();
export const storage = app.storage();
export const auth = app.auth();
export const functions = app.functions();
export const analytics = app.analytics();
export const firebase = fb;

// if (process.env.NODE_ENV === 'development') {
// fb.functions().useFunctionsEmulator('http://localhost:5001');
// }

// db.enablePersistence({ synchronizeTabs: true }).catch(err => {
//   if (err.code === 'failed-precondition') {
//     console.warn(
//       'failed-precondition',
//       'Multiple tabs open, persistence can only be enabled in one tab at a a time.',
//     );
//     // Multiple tabs open, persistence can only be enabled
//     // in one tab at a a time.
//     // ...
//   } else if (err.code === 'unimplemented') {
//     console.warn(
//       'unimplemented',
//       'The current browser does not support all of the features required to enable persistence.',
//     );
//     // The current browser does not support all of the
//     // features required to enable persistence
//     // ...
//   }
// });

window.auth = auth;
