import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Drawer, DrawerProps } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/CancelRounded';
import { IconButton } from '@21st-night/web';

interface AppDrawerProps extends DrawerProps {
  /**
   * Callback fired when the close button
   * or backdrop is clicked.
   */
  onClose: (event: React.MouseEvent) => void;
  /**
   * If `true`, the drawer will be open.
   */
  open?: boolean;
}

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {},
    content: {
      display: 'flex',
      flexDirection: 'column',
      width: 320,
      padding: 40,
      backgroundColor: '#0E0E0E',
      color: '#FFF',
    },
    closeButton: {
      position: 'absolute',
      right: 4,
      top: 4,
      color: 'rgba(255, 255, 255, 0.6)',
    },
    onboarding: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    navigationList: {
      listStyle: 'none',
      padding: 0,
      '& > li': {
        marginBottom: theme.spacing(1),
        ...theme.typography.body1,
        '& > a': {
          color: 'inherit',
          textDecoration: 'none',
          fontSize: '1.5rem',
          fontWeight: 700,
          transition: theme.transitions.create(['color'], {
            duration: theme.transitions.duration.shortest,
          }),
          '&:hover': {
            color: 'rgba(255, 255, 255, 0.8)',
          },
          '&:visited': {
            color: 'inherit',
          },
        },
      },
    },
    onboardingList: {
      listStyle: 'none',
      padding: 0,
      '& > li': {
        marginBottom: theme.spacing(1),
        ...theme.typography.body1,
        color: 'rgba(255, 255, 255, 0.8)',
        '& > a': {
          color: 'inherit',
          '&:visited': {
            color: 'inherit',
          },
        },
      },
    },
    version: {
      color: 'rgba(255, 255, 255, 0.5)',
    },
    rounded: {
      borderRadius: 8,
    },
  }),
);

const AppDrawer: React.FC<AppDrawerProps> = ({
  children,
  className: classNameProp,
  onClose,
  open = false,
  ...other
}) => {
  const classes = useStyles();

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      classes={{ paper: classes.content }}
      className={clsx(classes.root, classNameProp)}
      {...other}
    >
      <IconButton
        className={classes.closeButton}
        onClick={onClose}
        aria-label="Close main navigation"
      >
        <CloseIcon />
      </IconButton>

      <ul className={classes.navigationList}>
        <li>
          <Link onClick={onClose} to="/dashboard">
            Dashboard
          </Link>
        </li>
        <li>
          <Link onClick={onClose} to="/subscription">
            Subscription
          </Link>
        </li>
        <li>
          <Link onClick={onClose} to="/community-decks">
            Community decks
          </Link>
        </li>
        <li>
          <Link to="/logout">Logout</Link>
        </li>
      </ul>

      <div className={classes.onboarding}>
        <Typography variant="h3">Use 21st for...</Typography>
        <ul className={classes.onboardingList}>
          <li>
            <Link to="/onboarding/standerdized-test" onClick={onClose}>
              For a standardized test
            </Link>
          </li>
          <li>
            Like the{' '}
            <Link to="/onboarding/gmat" onClick={onClose}>
              GMAT
            </Link>
            , the{' '}
            <Link to="/onboarding/gre" onClick={onClose}>
              GRE
            </Link>
            , the{' '}
            <Link to="/onboarding/lsat" onClick={onClose}>
              LSAT
            </Link>
            , or the{' '}
            <Link to="/onboarding/mcat" onClick={onClose}>
              MCAT
            </Link>
            .
          </li>
          <li>
            <Link to="/onboarding/learn" onClick={onClose}>
              To rapidly learn a lot of content.
            </Link>
          </li>
          <li>
            <Link to="/onboarding/skill" onClick={onClose}>
              To master a process or skill.
            </Link>
          </li>
          <li>
            For{' '}
            <Link to="/onboarding/teach" onClick={onClose}>
              teaching
            </Link>{' '}
            or{' '}
            <Link to="/onboarding/tutor" onClick={onClose}>
              tutoring
            </Link>
            .
          </li>
          <li>
            <Link to="/onboarding/final" onClick={onClose}>
              For a final exam.
            </Link>
          </li>
        </ul>
      </div>
      {children}
      <div className={classes.version}>
        Version {process.env.REACT_APP_VERSION}
      </div>
    </Drawer>
  );
};

export default AppDrawer;
