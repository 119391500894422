import React, { useCallback, useState } from 'react';
import {
  OnboardingCoachingInfoDialog,
  OnboardingCoachingForm,
  OnboardingCoachingInfoDialogProps,
  completeOnboarding,
} from '@21st-night/onboarding-web';
import { useFirebase } from '@21st-night/core';

export interface CoachingDialogProps {
  open: boolean;
  onClose: () => void;
  variant: OnboardingCoachingInfoDialogProps['variant'];
}

export const CoachingDialog: React.FC<CoachingDialogProps> = ({
  onClose,
  open,
  variant,
}) => {
  const { functions } = useFirebase();
  const [coachingFormOpen, setCoachingFormOpen] = useState(false);
  const [loadingCoachingEmail, setLoadingCoachingEmail] = useState(false);

  const openCoachingForm = useCallback(() => {
    onClose();
    setCoachingFormOpen(true);
  }, []);

  const closeCoachingForm = useCallback(() => {
    setCoachingFormOpen(false);
  }, []);

  const handleSubmitCoachingForm = useCallback(async values => {
    const sendCoachingContactEmail = functions.httpsCallable(
      'sendCoachingContactForm',
    );
    setLoadingCoachingEmail(true);
    await sendCoachingContactEmail(values);
    setCoachingFormOpen(false);
  }, []);

  return (
    <>
      <OnboardingCoachingInfoDialog
        open={open}
        variant={variant}
        onClose={onClose}
        onClickActionButton={openCoachingForm}
      />
      <OnboardingCoachingForm
        open={coachingFormOpen}
        loading={loadingCoachingEmail}
        onClose={closeCoachingForm}
        onSubmitForm={handleSubmitCoachingForm}
      />
    </>
  );
};
