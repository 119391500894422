/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    maxWidth: 340,
    margin: '0 auto',
  },
  title: {
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
  },
}));

function DeckSharePublishSuccess({
  className: classNameProp,
  deckId,
  ...other
}) {
  const classes = useStyles();
  // const url = `${process.env.REACT_APP_BASE_URL}/deck/${deckId}/preview`;

  return (
    <div className={clsx(classes.root, classNameProp)} {...other}>
      <Typography gutterBottom variant="h5" className={classes.title}>
        Success
      </Typography>
      <Typography
        gutterBottom
        variant="body2"
        color="textSecondary"
        className={classes.description}
      >
        Your deck has been submitted for approval. We'll let you know when it's
        published!
      </Typography>
    </div>
  );
}

DeckSharePublishSuccess.propTypes = {
  className: PropTypes.string,
  deckId: PropTypes.string.isRequired,
};

DeckSharePublishSuccess.defaultProps = {};

export default DeckSharePublishSuccess;
