import React, { useRef } from 'react';
import {
  ButtonBase,
  IconButton,
  Menu,
  Tooltip,
  TooltipProps,
} from '@21st-night/ui';
import { makeStyles, cn } from '@21st-night/styles';
import { ExpandMore } from '@21st-night/icons';

export interface DeckPrimaryActionButtonProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  label: string;
  tooltip?: TooltipProps['title'];
}

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    transition: theme.transitions.create(['box-shadow'], {
      duration: theme.transitions.duration.short,
    }),
    borderRadius: 3,
    boxShadow:
      '0 1px 3px rgba(0, 0, 0, 0.2), rgba(15, 15, 15, 0.05) 0px 0px 0px 1px',
    '&:hover': {
      boxShadow:
        '0 4px 6px rgba(0, 0, 0, 0.2), rgba(15, 15, 15, 0.05) 0px 0px 0px 1px',
      '& > $secondaryButton': {
        opacity: 1,
      },
    },
    '&:active': {
      transitionDuration: theme.transitions.duration.shortest,
      boxShadow:
        '0 1px 1px rgba(0, 0, 0, 0.2), rgba(15, 15, 15, 0.05) 0px 0px 0px 1px',
    },
  },
  primaryButton: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 500,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2, 6),
  },
  secondaryButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 9,
    transition: theme.transitions.create(['opacity', 'background-color'], {
      duration: theme.transitions.duration.short,
    }),
    opacity: 0,
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  dropdownIcon: {
    color: theme.palette.text.disabled,
    width: 24,
    height: 24,
  },
}));

export const DeckPrimaryActionButton: React.FC<DeckPrimaryActionButtonProps> = ({
  label,
  className,
  children,
  onClick,
  disabled,
  tooltip,
  id,
  ...other
}) => {
  const classes = useStyles();
  const menuButton = useRef<HTMLButtonElement | null>(null);
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleClickSecondaryButton = () => {
    setMenuOpen(Boolean(menuButton.current));
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  let button = (
    <ButtonBase
      disableRipple
      className={classes.primaryButton}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </ButtonBase>
  );

  if (tooltip) {
    button = (
      <Tooltip placement="top" title={tooltip}>
        <div>{button}</div>
      </Tooltip>
    );
  }

  return (
    <div className={cn(classes.root, className)} {...other}>
      {button}
      {Boolean(children) && (
        <>
          <IconButton
            disableRipple
            disableScaling
            ref={menuButton}
            aria-controls={id}
            aria-haspopup="true"
            onClick={handleClickSecondaryButton}
            className={classes.secondaryButton}
          >
            <ExpandMore className={classes.dropdownIcon} />
          </IconButton>
          <Menu
            anchorReference="anchorPosition"
            MenuListProps={{ dense: true }}
            id={id}
            keepMounted
            anchorPosition={{
              top: menuButton.current
                ? menuButton.current.getBoundingClientRect().bottom + 9
                : 0,
              left: menuButton.current
                ? menuButton.current.getBoundingClientRect().left + 44
                : 0,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={menuOpen}
            onClose={handleCloseMenu}
          >
            {children}
          </Menu>
        </>
      )}
    </div>
  );
};
