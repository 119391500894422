/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { TextField } from '@21st-night/web';

export const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 340,
    margin: '0 auto',
  },
  terms: {
    marginTop: theme.spacing(1),
  },
  termsLabel: {
    fontSize: '.8rem',
    lineHeight: '1rem',
  },
}));

function DeckShareAnalyticsForm({
  className: classNameProp,
  children,
  disabled,
  errors,
  onFieldChange,
  values,
  ...other
}) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, classNameProp)} {...other}>
      <TextField
        multiline
        fullWidth
        rows={3}
        margin="normal"
        variant="bordered"
        label="Emails"
        placeholder="john.doe@gmail.com, jane.doe@yahoo.com"
        helperText={
          errors.emails ||
          "Write the email addresses of the people you want to share this deck's analytics with."
        }
        name="emails"
        onChange={onFieldChange}
        value={values.emails}
        error={!!errors.emails}
        disabled={disabled}
      />
      <TextField
        multiline
        fullWidth
        rows={3}
        margin="normal"
        variant="bordered"
        label="Message"
        placeholder="Here's my deck..."
        helperText={
          errors.message || 'Add an optional message to the invitation email.'
        }
        name="message"
        onChange={onFieldChange}
        value={values.message}
        error={!!errors.message}
        disabled={disabled}
      />
      <FormControlLabel
        control={
          <Checkbox
            error={!!errors.terms}
            name="terms"
            checked={values.terms}
            onChange={onFieldChange}
            disabled={disabled}
          />
        }
        classes={{ root: classes.terms, label: classes.termsLabel }}
        label={
          <span>
            I have read and agreed to 21st Night&apos;s{' '}
            <a
              href="https://get21stnight.com/terms-of-use/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>
          </span>
        }
      />
      {errors.terms && <FormHelperText error>{errors.terms}</FormHelperText>}
    </div>
  );
}

DeckShareAnalyticsForm.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /**
   * If `true`, all fields will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Field errors.
   */
  errors: PropTypes.shape({
    emails: PropTypes.string,
    message: PropTypes.string,
    terms: PropTypes.string,
  }),
  /**
   * Callback fired when a field changes.
   */
  onFieldChange: PropTypes.func.isRequired,
  /**
   * The field values.
   */
  values: PropTypes.shape({
    emails: PropTypes.string,
    message: PropTypes.string,
    terms: PropTypes.bool,
  }).isRequired,
};

DeckShareAnalyticsForm.defaultProps = {
  errors: {},
  disabled: false,
};

export default DeckShareAnalyticsForm;
