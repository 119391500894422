/* eslint-disable @typescript-eslint/unbound-method */
import React, { useCallback, useState } from 'react';
import { useStudyPlan, StudyPlanItem } from '@21st-night/study-plan-web';
import { StudyPlanSubItem, StudyPlanItemFormContainer } from '@21st-night/web';

export interface StudyPlanSubItemContainerProps {
  background?: 'paper' | 'default';
  item: StudyPlanItem;
}

export const StudyPlanSubItemContainer: React.FC<StudyPlanSubItemContainerProps> = ({
  item,
  background,
}) => {
  const plan = useStudyPlan();
  const [edit, setEdit] = useState(false);

  const onClick = useCallback(() => {
    plan.toggleItemDone(item);
  }, [plan, item]);

  const onClickDelete = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      plan.deleteSubItem(item);
    },
    [plan, item],
  );

  const onClickEdit = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setEdit(true);
  }, []);

  const closeEditForm = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setEdit(false);
  }, []);

  const handleSubmitEdit = useCallback(
    (title: string) => {
      plan.updateItem(item.id, { title });
      setEdit(false);
    },
    [plan, item],
  );

  return (
    <>
      {!edit ? (
        <StudyPlanSubItem
          background={background}
          key={item.id}
          title={item.title}
          done={item.done}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
          onClick={onClick}
        />
      ) : (
        <StudyPlanItemFormContainer
          key={item.id}
          placeholder="SubItem"
          initialValue={item.title}
          onClickCancel={closeEditForm}
          onSubmit={handleSubmitEdit}
        />
      )}
    </>
  );
};
