import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Redirect, Route } from 'react-router-dom';
import userContext from '../../api/user/userContext';
import PageLoader from '../PageLoader';
import { useUser } from '@21st-night/core';

function PrivateRoute({ location, allowUnsubscribed, ...other }) {
  const user = useUser();
  const pathnameParts = location.pathname.split('/');
  const isAnalyticsRoute =
    pathnameParts[1] === 'deck' && pathnameParts[3] === 'progress';

  if (user.loading) {
    return <PageLoader />;
  }

  if (!user.id) {
    if (isAnalyticsRoute) {
      return (
        <Redirect
          to={{
            pathname: '/progress/login',
            state: { from: location },
          }}
        />
      );
    }

    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />
    );
  }

  if (
    location.pathname !== '/welcome' &&
    !user.isAnonymous &&
    user.segmentation &&
    !user.segmentation.completed
  ) {
    return (
      <Redirect
        to={{
          pathname: '/welcome',
          state: { from: location },
        }}
      />
    );
  }

  if (!allowUnsubscribed && !user.hasActiveSubscription) {
    return (
      <Redirect
        to={{
          pathname: '/subscribe',
          state: { from: location },
        }}
      />
    );
  }

  if (
    user.type === 'analytics' &&
    location.pathname !== '/' &&
    !isAnalyticsRoute
  ) {
    const deck = user.analyticsDecks[0];
    return <Redirect to={`/deck-progress/${deck.deckId}/${deck.userId}`} />;
  }

  return <Route {...other} />;
}

PrivateRoute.propTypes = {
  /**
   * ReactRouter's loaction prop. Passed to
   * new route on redirect.
   */
  location: ReactRouterPropTypes.location,
  /**
   * If `true`, a logged in user will be
   * able to access this route even if they
   * do no have an active subscription.
   */
  allowUnsubscribed: PropTypes.bool,
};

PrivateRoute.defaultProps = {};

export default PrivateRoute;
