/* eslint-disable @typescript-eslint/unbound-method */
import React, { useCallback, useState } from 'react';
import {
  generateStudyPlanItem,
  StudyPlanDay,
  useStudyPlan,
} from '@21st-night/study-plan-web';
import { Button } from '@21st-night/ui';
import { StudyPlanItemFormContainer } from '@21st-night/web';

export interface StudyPlanAddTaskProps {
  day: StudyPlanDay;
}

export const StudyPlanAddTask: React.FC<StudyPlanAddTaskProps> = ({ day }) => {
  const plan = useStudyPlan();
  const [newTaskForm, setNewTaskForm] = useState(false);

  const openNewTaskForm = useCallback(() => setNewTaskForm(true), []);

  const closeNewTaskForm = useCallback(() => setNewTaskForm(false), []);

  const handleSubmitNewTask = useCallback(
    (title: string) => {
      if (!plan.days[day.id]) {
        plan.addDayWithItem(day, title);
      } else {
        const item = generateStudyPlanItem(plan.days[day.id], title);
        plan.addItem(item);
      }
      closeNewTaskForm();
    },
    [day, plan, closeNewTaskForm],
  );

  return (
    <div>
      {!newTaskForm && (
        <Button
          style={{ marginLeft: 24 }}
          size="small"
          variant="text"
          onClick={openNewTaskForm}
        >
          + Add a task
        </Button>
      )}
      {newTaskForm && (
        <StudyPlanItemFormContainer
          placeholder="New item"
          onClickCancel={closeNewTaskForm}
          onSubmit={handleSubmitNewTask}
        />
      )}
    </div>
  );
};
