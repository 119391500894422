import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CardReviewProgress,
  FlashcardReviewProgress,
  deserializeCardProgress,
  ReviewLayout,
  ReviewRating,
  useReview,
  useReviewState,
} from '@21st-night/review-web';
import { useDeck, DeckEditCardDialog } from '@21st-night/deck-web';
import { convertToPlainText, useTimeActive } from '@21st-night/core';
import { deserializeDocument } from '@21st-night/editor-web';
import { useFirebase } from '@21st-night/utils-web';

export const DeckReview: React.FC = () => {
  const { db, auth, imageUrl } = useFirebase();
  const [time, resetTime] = useTimeActive(120);
  const history = useHistory();
  const deck = useDeck();
  const review = useReview();
  const state = useReviewState();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [cardProgressDocs, setCardProgressDocs] = useState<
    Record<string, CardReviewProgress | FlashcardReviewProgress>
  >({});

  useEffect(() => {
    if (!auth.currentUser) {
      return;
    }
    db.collection('users')
      .doc(auth.currentUser.uid)
      .collection('card-progress')
      .get()
      .then(snapshot => {
        setCardProgressDocs(
          snapshot.docs.reduce(
            (record, doc) => ({
              ...record,
              [doc.id]: deserializeCardProgress(doc),
            }),
            {},
          ),
        );
      });
  }, [db, auth.currentUser]);

  const currentCard = useMemo(
    () => deck.cards.find(card => card.id === review.currentCard) || null,
    [review.currentCard, deck.cards],
  );
  const currentNote = useMemo(
    () =>
      (currentCard &&
        currentCard.note &&
        deck.notes.find(note => note.id === currentCard.note)) ||
      null,
    [currentCard, deck.notes],
  );

  const handleRateCard = useCallback(
    (rating: ReviewRating) => {
      if (currentCard) {
        if (currentCard.type === 'flashcard') {
          review.rateFlashcard(
            currentCard.id,
            (cardProgressDocs[currentCard.id] as FlashcardReviewProgress) ||
              null,
            rating,
            time,
          );
        } else {
          review.rateErrorLog(
            currentCard.id,
            cardProgressDocs[currentCard.id] || null,
            rating,
            time,
          );
        }

        resetTime();
        if (state.remainingCards.length === 1) {
          review.endReviewSession();
          history.replace(`/deck/${deck.id}/content`);
        }
      }
    },
    [
      currentCard,
      review,
      cardProgressDocs,
      time,
      resetTime,
      state.remainingCards,
      deck.id,
      history,
    ],
  );

  const handleCheckAnswer = useCallback(() => {
    if (!currentCard) {
      return;
    }

    const answerText = convertToPlainText(
      JSON.parse(currentCard.answer),
    ).trim();

    review.checkWrittenAnswer(answerText);
  }, [currentCard, review]);

  const handleGoBackToDeck = useCallback(() => {
    history.push(`/deck/${deck.id}/content`);
  }, [deck.id, history]);

  const openEditDialog = useCallback(() => {
    setEditDialogOpen(true);
  }, []);

  const closeEditDialog = useCallback(() => {
    setEditDialogOpen(false);
  }, []);

  if (!currentCard) {
    return <div />;
  }

  return (
    <>
      <ReviewLayout
        imageUrl={imageUrl}
        cardType={currentCard.type}
        view={review.view}
        onChangeView={review.setView}
        reviewMode={review.mode}
        onChangeReviewMode={review.setReviewMode}
        onRate={handleRateCard}
        onClickSkip={review.skipCurrentCard}
        writtenAnswer={review.writtenAnswer}
        writtenAnswerState={review.writtenAnswerState}
        starred={currentCard.starred}
        onChangeWrittenAnswer={event =>
          review.setWrittenAnswer(event.target.value)
        }
        onClickStar={() =>
          currentCard.starred
            ? deck.unstarCard(currentCard.id)
            : deck.starCard(currentCard.id)
        }
        onClickEdit={openEditDialog}
        onClickCheckAnswer={handleCheckAnswer}
        onClickBack={handleGoBackToDeck}
        question={deserializeDocument(currentCard.question)}
        answer={deserializeDocument(currentCard.answer)}
        note={
          currentNote ? deserializeDocument(currentNote.content) : undefined
        }
        summary={
          currentCard.summary ? deserializeDocument(currentCard.summary) : null
        }
        explanation={
          currentCard.explanation
            ? deserializeDocument(currentCard.explanation)
            : null
        }
      />
      <DeckEditCardDialog
        card={currentCard}
        open={editDialogOpen}
        onClose={closeEditDialog}
      />
    </>
  );
};
