import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  list: {
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
    maxHeight: 300,
  },
  fadeOut: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    height: 50,
    backgroundImage:
      'linear-gradient(rgba(245, 247, 250, .3) 0%, rgba(245, 247, 250, 1) 100%)',
  },
  spacer: {
    height: 30,
  },
}));

function DeckShareEmailList({
  className: classNameProp,
  children,
  emails,
  ...other
}) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, classNameProp)} {...other}>
      <List dense className={classes.list}>
        {emails.map(email => (
          <ListItem key={email}>
            <ListItemText>{email}</ListItemText>
          </ListItem>
        ))}
        <li className={classes.spacer} />
      </List>
      <div className={classes.fadeOut} />
    </div>
  );
}

DeckShareEmailList.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /**
   * The list of emails to render.
   */
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DeckShareEmailList;
