const studyPlanTemplates = {
  gmat4Month: 'OvlWpO9YemQih44SHtQF',
  gmat2Month: 'dHxlsJhnAY2BNuy1Tl0c',
  lsat3Month: '8Fzmv10FNIzrwoT63fqO',
  lsat1Month: 'gyKC7NKTYJWWOqOFW4LR',
  mcat4Month: 'ba7ixZRwTdaIqFFJo4qt',
  mcat1Month: 'ubSSsMYEDx6Dr1RuveeQ',
  gre2Month: '7amN2ImObD9UKNEaafhB',
  gre1Month: '7mnTs8uNXbieIa0qelTL',
};

function getStudyPlanTemplateId(test: string, length: number): string {
  switch (test) {
    case 'GMAT':
      return length > 89
        ? studyPlanTemplates.gmat4Month
        : studyPlanTemplates.gmat2Month;
    case 'LSAT':
      return length > 44
        ? studyPlanTemplates.lsat3Month
        : studyPlanTemplates.lsat1Month;
    case 'MCAT':
      return length > 80
        ? studyPlanTemplates.mcat4Month
        : studyPlanTemplates.mcat1Month;
    case 'GRE':
      return length > 44
        ? studyPlanTemplates.gre2Month
        : studyPlanTemplates.gre1Month;
    default:
      return '';
  }
}

export default getStudyPlanTemplateId;
