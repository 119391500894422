/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { captureException } from '@sentry/browser';
import { completeStep } from '@21st-night/onboarding-web';
import Typography from '@material-ui/core/Typography';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import SchoolIcon from '@material-ui/icons/School';
import PublicIcon from '@material-ui/icons/Public';
import ChartIcon from '@material-ui/icons/BarChartRounded';
import { createRichTextDocument } from '@21st-night/core';
import { Button, LoadingIndicator } from '@21st-night/web';
import analytics from '../../../../libs/analytics';
import { functions, db } from '../../../../libs/firebase';
import DeckShareOptions from '../../components/DeckShareOptions';
import DeckShareOption from '../../components/DeckShareOption';
import DeckShareDialog from '../../components/DeckShareDialog';
import DeckShareTeachForm from '../../components/DeckShareTeachForm';
import DeckShareAnalyticsForm from '../../components/DeckShareAnalyticsForm';
import DeckShareTeacherForm from '../../components/DeckShareTeacherForm';
import DeckShareCollaborateForm from '../../components/DeckShareCollaborateForm';
import DeckSharePublishForm from '../../components/DeckSharePublishForm';
import useForm from '../../../../libs/hooks/useForm';
import getEmailsFromString from '../../../../libs/helpers/getEmailsFromString';
import DeckShareTeachSuccess from '../../components/DeckShareTeachSuccess';
import DeckShareInstructorSuccess from '../../components/DeckShareInstructorSuccess';
import DeckShareCollaborateSuccess from '../../components/DeckShareCollaborateSuccess';
import DeckShareAnalyticsSuccess from '../../components/DeckShareAnalyticsSuccess';
import DeckSharePublishSuccess from '../../components/DeckSharePublishSuccess';

// Initialize inviteStudentsToDeck Firebase function
export const inviteStudentsToDeck = functions.httpsCallable(
  'inviteStudentsToDeck',
);
// Initialize inviteCollaboratorsToDeck Firebase function
export const inviteCollaboratorsToDeck = functions.httpsCallable(
  'inviteCollaboratorsToDeck',
);
// Initialize inviteInstructorsToDeck Firebase function
export const inviteInstructorsToDeck = functions.httpsCallable(
  'inviteInstructorsToDeck',
);
// Initialize inviteAnalyticsToDeck Firebase function
export const inviteAnalyticsToDeck = functions.httpsCallable(
  'inviteAnalyticsToDeck',
);
// Initialize publishDeck Firebase function
export const publishDeck = functions.httpsCallable('publishDeck');

export const ERRORS = {
  EMAILS_REQUIRED: 'Please enter at least one email address',
  EMAILS_NONE_FOUND:
    "Couldn't find any emails in this text. Please ensure that email addresses are seperated by a space or a comma.",
  DESCRIPTION_REQUIRED: 'Required',
  TERMS_REQUIRED: 'Required',
};

const shareOptions = [
  {
    id: 'collaborate',
    title: 'Collaborators',
    color: '#7C4DFF',
    icon: <PeopleIcon />,
    description: (
      <div>
        <Typography paragraph>
          Share this deck with friends or classmates to collaborate on creating
          notes and cards together.
        </Typography>
        <Typography paragraph>
          Your review progress data will not be shared.
        </Typography>
      </div>
    ),
  },
  {
    id: 'teach',
    title: 'Students',
    color: '#448AFF',
    icon: <SchoolIcon />,
    description: (
      <div>
        <Typography paragraph>Share this deck with your students.</Typography>
        <Typography paragraph>
          You will be able to track each student's review progress. You can also
          add custom cards and notes to each student's copy of the deck.
        </Typography>
      </div>
    ),
  },
  {
    id: 'instructor',
    title: 'Instructors',
    color: '#448AFF',
    icon: <PersonIcon />,
    description: (
      <div>
        <Typography paragraph>
          Share this deck with your tutor or teacher.
        </Typography>
        <Typography paragraph>
          They will be able to track your review progress and will be able to
          add new notes and cards as well as edit existing ones.
        </Typography>
      </div>
    ),
  },
  {
    id: 'analytics',
    title: 'Analytics',
    color: '#795548',
    icon: <ChartIcon />,
    description: (
      <div>
        <Typography paragraph>
          Share your progress with an instructor or parent.
        </Typography>
        <Typography paragraph>
          They will be able to see your notes and cards and analytics but will
          not be able to modify the deck.
        </Typography>
      </div>
    ),
  },
  {
    id: 'publish',
    title: 'Publish',
    color: '#FF3D00',
    icon: <PublicIcon />,
    description: (
      <div>
        <Typography paragraph>
          Publish this deck to make it available to the 21st Night community.
        </Typography>
        <Typography paragraph>
          Anyone will be able to study the deck, but only you can edit it.
        </Typography>
        <Typography paragraph>
          Excellent published decks will have the chance to receive a one year's
          free subscription to 21st Night!
        </Typography>
        <Typography paragraph>
          Note that once published, a deck cannot be unpublished.
        </Typography>
      </div>
    ),
  },
];

const DeckShareDialogContainer = ({ deck, user }) => {
  let options = shareOptions;
  const [view, setView] = useState('options');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [parsedEmails, setParsedEmails] = useState([]);
  const [description, setDescription] = useState(createRichTextDocument());
  const [values, onFieldChange, clearForm] = useForm({
    emails: '',
    message: '',
    canEdit: true,
    terms: false,
  });

  if (deck.public) {
    options = [shareOptions[1], shareOptions[3]];
  }

  if (user.role === 'student') {
    options = [shareOptions[2], shareOptions[4]];
  }

  if (deck.roles.includes('student')) {
    options = [shareOptions[2], shareOptions[3]];
  }

  if (user.role === 'tutor') {
    options = [shareOptions[1], shareOptions[4]];
  }

  function isShareView() {
    return ['collaborate', 'teach', 'instructor', 'analytics'].includes(view);
  }

  function setOtherError(error) {
    let errorMessage = error.message;

    if (errorMessage === 'internal') {
      errorMessage =
        'An interval server error occured. Please try again later.';
    }

    setErrors({ other: errorMessage });
  }

  function validateForm() {
    const validationErrors = {};

    if (isShareView()) {
      if (!values.emails) {
        validationErrors.emails = ERRORS.EMAILS_REQUIRED;
      } else if (!getEmailsFromString(values.emails).length) {
        validationErrors.emails = ERRORS.EMAILS_NONE_FOUND;
      }
    } else if (view === 'publish') {
      if (!description) {
        validationErrors.description = ERRORS.DESCRIPTION_REQUIRED;
      }
    }

    if (!values.terms) {
      validationErrors.terms = ERRORS.TERMS_REQUIRED;
    }

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  }

  async function handleSubmit() {
    if (validateForm()) {
      setLoading(true);
      if (view === 'teach') {
        const parsed = getEmailsFromString(values.emails);
        setParsedEmails(parsed);
        try {
          await inviteStudentsToDeck({
            emails: parsed,
            deckId: deck.id,
            message: values.message,
          });
          setTimeout(() => {
            completeStep(db, user.id, 'invite-student');
          }, 10000);
        } catch (error) {
          captureException(error);
          setOtherError(error);
        }
        setView('teach-success');
      } else if (view === 'collaborate') {
        const parsed = getEmailsFromString(values.emails);
        setParsedEmails(parsed);
        try {
          await inviteCollaboratorsToDeck({
            emails: parsed,
            deckId: deck.id,
            message: values.message,
            canEdit: values.canEdit,
          });
          user.incrementStatistic('deck-share-collaborators');
        } catch (error) {
          captureException(error);
          setOtherError(error);
        }
        setView('collaborate-success');
      } else if (view === 'instructor') {
        const parsed = getEmailsFromString(values.emails);
        setParsedEmails(parsed);
        try {
          await inviteInstructorsToDeck({
            emails: parsed,
            deckId: deck.id,
            message: values.message,
          });
          user.incrementStatistic('deck-share-teachers');
        } catch (error) {
          captureException(error);
          setOtherError(error);
        }
        setView('instructor-success');
      } else if (view === 'analytics') {
        const parsed = getEmailsFromString(values.emails);
        setParsedEmails(parsed);
        try {
          await inviteAnalyticsToDeck({
            emails: parsed,
            deckId: deck.id,
            message: values.message,
          });
          user.incrementStatistic('deck-share-analytics');
        } catch (error) {
          captureException(error);
          setOtherError(error);
        }
        setView('analytics-success');
      } else if (view === 'publish') {
        try {
          await publishDeck({
            description: JSON.stringify(description),
            deckId: deck.id,
            userId: user.id,
          });
          setView('publish-success');
        } catch (error) {
          captureException(error);
          setOtherError(error);
        }
      }
      setLoading(false);

      // Log the event to analytics
      analytics.logEvent('share_deck', {
        type: view,
      });
    }
  }

  function handleClose() {
    if (view === 'teach-success') {
      completeStep(db, user.id, 'invite-student');
    }
    clearForm();
    setView('options');
  }

  const optionCards = options.map(option => (
    <DeckShareOption
      key={option.id}
      {...option}
      onSelect={() => setView(option.id)}
    />
  ));

  let actions = [];

  if (view !== 'options') {
    actions.push(
      <Button variant="clear" onClick={handleClose}>
        Cancel
      </Button>,
    );
  }

  if (isShareView()) {
    actions.push(
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Share
      </Button>,
    );
  }

  if (view === 'publish') {
    actions.push(
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Publish
      </Button>,
    );
  }

  if (loading) {
    actions = <LoadingIndicator size={36} />;
  } else if (
    view === 'collaborate-success' ||
    view === 'teach-success' ||
    view === 'instructor-success' ||
    view === 'analytics-success' ||
    view === 'publish-success'
  ) {
    actions = (
      <Button color="primary" size="large" onClick={handleClose}>
        Close
      </Button>
    );
  }

  return (
    <DeckShareDialog actions={actions}>
      {view === 'options' && <DeckShareOptions>{optionCards}</DeckShareOptions>}
      {view === 'teach' && (
        <DeckShareTeachForm
          values={values}
          onFieldChange={onFieldChange}
          errors={errors}
          disabled={loading}
        />
      )}
      {view === 'teach-success' && (
        <DeckShareTeachSuccess emails={parsedEmails} />
      )}
      {view === 'instructor' && (
        <DeckShareTeacherForm
          values={values}
          onFieldChange={onFieldChange}
          errors={errors}
          disabled={loading}
        />
      )}
      {view === 'instructor-success' && (
        <DeckShareInstructorSuccess emails={parsedEmails} />
      )}
      {view === 'collaborate' && (
        <DeckShareCollaborateForm
          values={values}
          onFieldChange={onFieldChange}
          errors={errors}
          disabled={loading}
        />
      )}
      {view === 'collaborate-success' && (
        <DeckShareCollaborateSuccess emails={parsedEmails} />
      )}
      {view === 'analytics' && (
        <DeckShareAnalyticsForm
          values={values}
          onFieldChange={onFieldChange}
          errors={errors}
          disabled={loading}
        />
      )}
      {view === 'analytics-success' && (
        <DeckShareAnalyticsSuccess emails={parsedEmails} />
      )}
      {view === 'publish' && (
        <DeckSharePublishForm
          values={values}
          onFieldChange={onFieldChange}
          onDescriptionChange={setDescription}
          description={description}
          errors={errors}
          disabled={loading}
        />
      )}
      {view === 'publish-success' && (
        <DeckSharePublishSuccess deckId={deck.id} />
      )}
    </DeckShareDialog>
  );
};

DeckShareDialogContainer.propTypes = {
  deck: PropTypes.object.isRequired,
};

export default DeckShareDialogContainer;
