import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { captureEvent } from '@sentry/browser';
import { PomodoroProvider } from '@21st-night/web';
import { useUser } from '@21st-night/core';
import dayjs from 'dayjs';

const UserPomodoroProvider = ({ children }) => {
  const [apiCalls, setApiCalls] = useState([]);
  const user = useUser();
  let focusTime;
  let breakTime;
  let cycle;
  let cycles;
  let mode;
  let paused;
  let remainingTime;
  let startedAt;
  let studyMode;

  if (user.pomodoro) {
    focusTime = user.pomodoro.focusTime;
    breakTime = user.pomodoro.breakTime;
    cycle = user.pomodoro.cycle;
    cycles = user.pomodoro.cycles;
    mode = user.pomodoro.mode;
    paused = user.pomodoro.paused;
    remainingTime = user.pomodoro.remainingTime;
    startedAt = user.pomodoro.startedAt;
    studyMode = user.pomodoro.studyMode;
  }

  function canCall() {
    setApiCalls([...apiCalls, new Date()]);
    let pastMinuteCalls = 0;
    let oneMinuteAgo = new Date();
    oneMinuteAgo.setMinutes(oneMinuteAgo.getMinutes() - 1);
    oneMinuteAgo = oneMinuteAgo.getTime();
    apiCalls.forEach(callDate => {
      if (callDate.getTime() > oneMinuteAgo) {
        pastMinuteCalls += 1;
      }
    });
    if (pastMinuteCalls < 30) {
      return true;
    }
    if (pastMinuteCalls > 30 && pastMinuteCalls <= 40) {
      captureEvent({
        message: 'Pomodoro API calls',
      });
    }

    return false;
  }

  function onClear(newRemainingTime) {
    if (canCall()) {
      user.update({
        'pomodoro.remainingTime': newRemainingTime || 0,
        'pomodoro.mode': 'inactive',
        'pomodoro.cycle': 1,
        'pomodoro.paused': true,
        'pomodoro.startedAt': new Date().getTime(),
      });
    }
  }

  useEffect(() => {
    if (
      startedAt &&
      (!dayjs(new Date(startedAt + remainingTime || 0)).isSame(
        new Date(),
        'day',
      ) ||
        (cycles && cycle && cycle === cycles && mode === 'break' && paused))
    ) {
      onClear(focusTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startedAt]);

  function updateFocusTime(time) {
    if (canCall()) {
      if (!user.pomodoro || time !== user.pomodoro.focusTime) {
        user.update({
          'pomodoro.focusTime': time,
        });
      }
    }
  }

  function updateBreakTime(time) {
    if (canCall()) {
      if (!user.pomodoro || time !== user.pomodoro.breakTime) {
        user.update({
          'pomodoro.breakTime': time,
        });
      }
    }
  }

  function updateCycles(cycles) {
    if (canCall()) {
      if (!user.pomodoro || cycles !== user.pomodoro.cycles) {
        user.update({
          'pomodoro.cycles': cycles,
        });
      }
    }
  }

  function onPause(newRemainingTime) {
    if (canCall()) {
      user.update({
        'pomodoro.paused': true,
        'pomodoro.remainingTime': newRemainingTime,
      });
    }
  }

  function onPlay(newMode, newRemainingTime) {
    if (!mode || mode === 'inactive') {
      user.incrementStatistic('timers-set');
    }

    if (canCall()) {
      user.update({
        'pomodoro.paused': false,
        'pomodoro.startedAt': new Date().getTime(),
        'pomodoro.remainingTime': newRemainingTime,
        'pomodoro.mode': newMode,
      });
    }
  }

  function onChangeMode(mode, newRemainingTime, cycle) {
    if (canCall()) {
      const update = {
        'pomodoro.mode': mode,
        'pomodoro.remainingTime': newRemainingTime,
        'pomodoro.startedAt': new Date().getTime(),
        'pomodoro.cycle': cycle,
      };
      user.update(update);
    }
  }

  function onReset(newRemainingTime) {
    if (canCall()) {
      remainingTime = newRemainingTime;
      user.update({
        'pomodoro.remainingTime': newRemainingTime,
        'pomodoro.startedAt': new Date().getTime(),
      });
    }
  }

  function onEnablePreset(preset) {
    if (canCall()) {
      user.update({
        'pomodoro.focusTime': preset.focusTime,
        'pomodoro.breakTime': preset.breakTime,
        'pomodoro.cycles': preset.cycles,
      });
    }
  }

  function onSetStudyModeEnabled(enabled) {
    if (canCall()) {
      user.update({
        'pomodoro.studyMode': enabled,
      });
    }
  }

  if (!user.id || user.loading) {
    return children;
  }

  return (
    <PomodoroProvider
      notificationAudioUrl="/audio/pomodoro-notification.wav"
      focusAlertAudioUrl="/audio/pomodoro-focus-alert.wav"
      focusTime={focusTime}
      breakTime={breakTime}
      cycle={cycle}
      cycles={cycles}
      mode={mode}
      paused={paused}
      studyMode={studyMode}
      onClear={onClear}
      onEnablePreset={onEnablePreset}
      onPause={onPause}
      onPlay={onPlay}
      onReset={onReset}
      onChangeMode={onChangeMode}
      onSetBreakTime={updateBreakTime}
      onSetFocusTime={updateFocusTime}
      onSetCycles={updateCycles}
      onSetStudyModeEnabled={onSetStudyModeEnabled}
      remainingTime={remainingTime}
      startedAt={startedAt}
    >
      {children}
    </PomodoroProvider>
  );
};

UserPomodoroProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserPomodoroProvider;
