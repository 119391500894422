import React, { useEffect, useState, useCallback } from 'react';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import { useDeck, DeckOverdueCardDialog, DeckCard } from '@21st-night/deck-web';
import {
  CardReviewProgress,
  FlashcardReviewProgress,
  ReviewRating,
  useReview,
} from '@21st-night/review-web';
import { Card } from '@21st-night/cards-web';

export const DeckOverdueCardReminder: React.FC = () => {
  const deck = useDeck();
  const review = useReview();
  const [overdueCard, setOverdueCard] = useState<DeckCard | null>(null);
  const [overdueCardDialog, setOverdueCardDialog] = useState(false);

  useEffect(() => {
    const overdueCards = deck.cards.filter(
      card =>
        card.nextReview &&
        card.status !== 'new' &&
        !card.retired &&
        dayjs(card.nextReview).isBefore(dayjs().subtract(9, 'day')),
    );

    if (overdueCards.length) {
      const cookieKey = `deck-${deck.id}-overdue-cards`;
      const lastShown = Cookies.get(cookieKey);
      const todayTime = new Date().getTime();
      const dayInMillis = 24 * 60 * 60 * 1000;

      if (lastShown && todayTime - parseInt(lastShown) < dayInMillis) {
        return;
      }

      Cookies.set(cookieKey, `${todayTime}`);
      setOverdueCard(overdueCards[0]);
      setOverdueCardDialog(true);
    }
  }, [deck.cards, deck.id]);

  const handleRate = useCallback(
    (event: React.MouseEvent, rating: ReviewRating): void => {
      setOverdueCardDialog(false);
      if (!overdueCard) {
        return;
      }

      if (overdueCard.type === 'flashcard') {
        review.rateFlashcard(
          overdueCard.id,
          (overdueCard as unknown) as FlashcardReviewProgress,
          rating,
        );
      } else {
        review.rateErrorLog(
          overdueCard.id,
          overdueCard as CardReviewProgress,
          rating,
        );
      }
    },
    [overdueCard, review],
  );

  if (!overdueCard) {
    return <div />;
  }

  return (
    <DeckOverdueCardDialog
      card={(overdueCard as unknown) as Card}
      imageUrl={process.env.REACT_APP_IMGIX_URL as string}
      open={overdueCardDialog}
      onRate={handleRate}
      onClose={() => setOverdueCardDialog(false)}
    />
  );
};
