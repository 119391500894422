import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { ActionButtons, Button } from '@21st-night/web';
import { Deck } from '@21st-night/types';
import { functions, storage } from '../../../libs/firebase';

export interface DeckExportCheatSheetProps {
  deck: Deck;
}

export type ExportType = 'PDF' | 'CSV';

// Initialize duplicateDeckCards Firebase function
export const duplicateDeckCards = functions.httpsCallable('duplicateDeckCards');
// Initialize exportCheatSheet function
export const generateCheatSheetPdf = functions.httpsCallable(
  'generateCheatSheetPdf',
);
export const generateCheatSheetCsv = functions.httpsCallable(
  'generateCheatSheetCsv',
);

const useStyles = makeStyles(theme => ({
  root: {},
  section: {
    maxWidth: 600,
    marginBottom: theme.spacing(4),
  },
  inlineField: {
    display: 'flex',
    width: '100%',
    maxWidth: 400,
  },
  inlineFieldInput: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  importDetails: {
    marginTop: theme.spacing(1),
  },
}));

const DeckSettings: React.FC<DeckExportCheatSheetProps> = ({ deck }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [exportType, setExportType] = useState<ExportType | null>(null);
  const [downloadURL, setDownloadURL] = useState('');
  const [importError, setImportError] = useState('');

  async function exportCheatSheet(type: ExportType): Promise<void> {
    setLoading(true);
    setExportType(type);
    setDownloadURL('');
    try {
      const result =
        type === 'PDF'
          ? await generateCheatSheetPdf({ deck: deck.id })
          : await generateCheatSheetCsv({ deck: deck.id });
      if (result && result.data) {
        const file = storage.ref(result.data.file);
        const url = await file.getDownloadURL();
        setDownloadURL(url);
        window.open(url);
      }
    } catch (err) {
      setImportError(err.message);
    }
    setLoading(false);
  }

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <Typography variant="overline">Export cheat sheet</Typography>
        <Typography gutterBottom variant="body2" color="textSecondary">
          Create a PDF or CSV of your cheat sheet for a quick and easy review.
        </Typography>
        <div className={classes.inlineField}>
          <ActionButtons spaced>
            <Button
              variant="contained"
              onClick={(): void => {
                exportCheatSheet('PDF');
              }}
              disabled={loading}
            >
              Export as PDF
            </Button>
            <Button
              variant="contained"
              onClick={(): void => {
                exportCheatSheet('CSV');
              }}
              disabled={loading}
            >
              Export as CSV
            </Button>
          </ActionButtons>
        </div>
        <div className={classes.importDetails}>
          {importError && (
            <Typography color="error">Error: {importError}</Typography>
          )}
          {loading && `Creating ${exportType}...`}
          {downloadURL && (
            <Link href={downloadURL} download={`${deck.name} Cheat Sheet`}>
              Download {exportType}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeckSettings;
