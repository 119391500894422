import React, { useContext, useState, useEffect, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { Button } from '@21st-night/web';
import userContext from '../../api/user/userContext';
import AppLayout from '../../components/AppLayout';

function UnsubscribeFromEmailsPage() {
  const user = useContext(userContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let calling;
    async function unsubscribe() {
      if (!user.loading && !calling) {
        try {
          calling = true;
          await user.unsubscribeFromEmails();
          setLoading(false);
        } catch (error) {
          setLoading(false);
          calling = false;
          setError(error.message);
        }
      }
    }

    unsubscribe();
  }, [user]);

  return (
    <AppLayout centerHorizontally centerVertically loading={loading}>
      {error && (
        <Fragment>
          <Typography gutterBottom color="error" variant="h5">
            An unexpected error occured: {error}
          </Typography>
          <Typography color="textSecondary" variant="h6">
            Please refresh the page to try again.
          </Typography>
        </Fragment>
      )}
      {!error && !loading && (
        <Fragment>
          <Typography gutterBottom color="textSecondary" variant="h5">
            You have been successfully unsubscribed.
          </Typography>
          <Button
            variant="contained"
            size="large"
            color="primary"
            component={Link}
            to="/dashboard"
          >
            Go to Dashboard
          </Button>
        </Fragment>
      )}
    </AppLayout>
  );
}

UnsubscribeFromEmailsPage.propTypes = {};

export default UnsubscribeFromEmailsPage;
